import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Eye, MessageSquare, User } from 'react-feather';
import { useStorage } from '../../Utils/Utils';
import TableActionIcon from '../../components/Table/TableActionIcon';
import FlexWrapper from '../FlexWrapper';
import DraftNotePreviewModal from '../../components/Modals/DraftNotePreviewModal';
import Tooltip from '../../components/popups/Tooltip';


const RequestsTableRow = ({ allRequests, data, idx, rowActivePreview, setRowActivePreview, expandedRows, refreshList }) => {
	const [document, setDocument] = useState({});
	const [me, setMe] = useState({});
	const [autoOpenComments, setAutoOpenComments] = useState(false)
	const storage = useStorage();

	useEffect(() => {
		setDocument(data)
		storage.getOrFetch(`/crm/me`).then(setMe)

		if (data) {
			if (data.peerApprovedDate != null) {
				data.peerReviewStatus = "Approved"
			} else if (data.peerRequestedDate != null) {
				data.peerReviewStatus = "Pending"
			} else {
				data.peerReviewStatus = "Not Requested"
			}
			if (data.complianceApprovedDate != null) {
				data.complianceReviewStatus = "Approved"
			} else if (data.complianceRequestedDate != null) {
				data.complianceReviewStatus = "Pending"
			} else {
				data.complianceReviewStatus = "Not Requested"
			}
			if (data.complianceApprovedDate != null) {
				data.complianceReviewStatus = "Approved"
			} else if (data.complianceRequestedDate != null) {
				data.complianceReviewStatus = "Pending"
			} else {
				data.complianceReviewStatus = "Not Requested"
			}
			if (data.complianceApprovedDate != null) {
				data.supervisorReviewStatus = "Approved"
			} else if (data.supervisorRequestedDate != null) {
				data.supervisorReviewStatus = "Pending"
			} else {
				data.supervisorReviewStatus = "Not Requested"
			}

		}
	}, [data])

	function getApprovers(document) {
		const { reviewResponderActions, approversNeeded } = document
		const iconsArray = new Array(approversNeeded).fill(<User color='#D6D8DE' />)
		const filteredActions = reviewResponderActions?.length > 0 && reviewResponderActions.filter(r => r.status === 'Approved' || r.status === 'Rejected')

		return (
			iconsArray.map((approver, index) => {
				const updatedApprover = filteredActions?.length > 0 ? filteredActions[index] : null
				const actionist = updatedApprover ? updatedApprover?.responderName && me.name && me.name === updatedApprover?.responderName ? 'You' : updatedApprover?.responderName : null
				if (updatedApprover) return (
					<Tooltip label={<p><span>{actionist + ': '}</span><span style={{ opacity: 0.5, fontSize: 13}}>{format(parseISO(updatedApprover.grantedAt), "hh:mmaaa, dd/MM/yyyy")}</span></p>} >
						<User color={updatedApprover.status == "Approved" ? "#56D1C3" : "#E66780"} />
					</Tooltip>
				)
				else return approver
			})
		)
	}


	return (
		<>
			{rowActivePreview === idx && <DraftNotePreviewModal data={data} doc={document} setRowActivePreview={setRowActivePreview} currentIndex={idx} allRequests={allRequests} compliance handleClose={() => { setRowActivePreview(null); setAutoOpenComments(false) }} autoOpenComments={autoOpenComments} setAutoOpenComments={setAutoOpenComments} refreshList={refreshList} />}
			<tr key={idx} className={expandedRows && document && expandedRows.includes(document.docId) ? "library-row-selected" : ""} onClick={() => setRowActivePreview(idx)}>
				<td>
					{document && document.createdDate
						? <Tooltip delay={1000} label={document.peerRequestedBy === "System" ? "Data was synchronised" : "File was created"}>
							<div style={{ display: 'flex', flexWrap: 'wrap' }}>
								<p style={{ paddingRight: 10 }}>{format(parseISO(document.createdDate), "do MMM yyyy")}</p>
								<p>{format(parseISO(document.createdDate), "hh:mm aaa")}</p>
							</div>
						</Tooltip>
						: "-"
					}
				</td>
				<td>
					<div className="tsi-details">
						<div className="tsi-label">
							{document && document.coverage}
						</div>
					</div>
				</td>
				<td className="research-title">
					<p>{document && document.title && document.title === '-' ? document.ticker : document?.title}</p>
				</td>
				<td>
					<p>{(document && document.author) || "-"}</p>
				</td>
				<td>
					{document ? getApprovers(document) : "-"}
				</td>
				{/* Row Actions */}
				<td>
					<FlexWrapper gap="l">
						<TableActionIcon colour="primary" noMargin icon={<Eye />} toolTip="Click to preview document" onClick={() => setRowActivePreview(idx)} />
						<TableActionIcon colour="primary" noMargin icon={<MessageSquare />} toolTip="Click to view messages" onClick={() => { setRowActivePreview(null); setAutoOpenComments(true) }} />
					</FlexWrapper>
				</td>
			</tr >
		</>
	);
}

export default RequestsTableRow;