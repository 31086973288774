import React, { useEffect, useState } from 'react';
import { Plus, TrendingUp } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import TickerListSearchField from '../../../components/Input/TickerListSearchField';
import SelectSearchField from '../../../components/Input/SelectSearchField';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import RemoveConfirmModal from '../../../components/Modals/RemoveConfirmModal';
import useWindowDimensions, { formatDate, useRemote } from '../../../Utils/Utils';
import TickerSectorItem from '../../../components/TickerSectorItem';
import toValueLabelPair from "../../../components/Functions/toValueLabelPair";
import { toast } from 'react-toastify';
import "./TickersList.scss";
import { useHistory } from 'react-router-dom';

const TickerList = ({ }) => {
	// Table widths and headers
	const colWidths = [40 + "%", 30 + "%", 10 + "%", 20 + "%"];
	const headers = ["Code/Name", "Sector", "Date Created", ""];
	const [showRecentlyAdded, setShowRecentlyAdded] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [filteredTickers, setFilteredTickers] = useState([])
	const [tickers, setTickers] = useState([]);
	const [exchanges, setExchanges] = useState([]);
	const [sectors, setSectors] = useState([]);
	const { width } = useWindowDimensions();
	const [tickerSearchTerm, setTickerSearchTerm] = useState("")
	const [exchangeSearchTerm, setExchangeSearchTerm] = useState("")
	const [sectorSearchTerm, setSectorSearchTerm] = useState("")
	const [tickersLoading, setTickersLoading] = useState(true)
	
	const remote = useRemote();
	const history = useHistory()

	useEffect(() => {
		remote.get("/crm/tickers").then(setTickers).then(() => setTickersLoading(false));
		remote.get("/crm/gics/sectors").then(setSectors);
		remote.get("/crm/exchanges").then(setExchanges);
	}, [])

	useEffect(() => {
		const resultsToFilter = [...tickers];
		const filteredResults = resultsToFilter.filter((ticker) => {
			let checks = []
			if (tickerSearchTerm){
				ticker.ric === tickerSearchTerm ? checks.push(true) : checks.push(false)
			}
			if (exchangeSearchTerm){
				ticker.exchangeId === exchangeSearchTerm.fullDTO.id ? checks.push(true) : checks.push(false)
			}
			if (sectorSearchTerm){
				ticker.gicSector === sectorSearchTerm.value ? checks.push(true) : checks.push(false)
			}
			return checks.includes(false) ? false : true
		})
		setFilteredTickers(filteredResults)
	}, [tickerSearchTerm, exchangeSearchTerm, sectorSearchTerm])

	// Table map
	const dataMap = (data) => data.map((ticker, idx) => {
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => history.push(ROUTE_PATHS[PAGES.ADMIN] + `/tickers/${ticker.id}`)}>
				<td><TickerSectorItem label={ticker.ric} extensionLabel={ticker.name} country={ticker.country} filename={ticker.ric} image={`/api/public/images/ticker/${ticker.id}.png`} /></td>
				<td>{ticker.gicSector}</td>
				<td>{ticker.dateAdded ? formatDate(ticker.dateAdded) : ""}</td>
				<TableActionIconGroup
					disabled
					download={{ hide: true }}
					edit={{ onClick: () => history.push(ROUTE_PATHS[PAGES.ADMIN] + `/tickers/${ticker.id}`) }}
					duplicate={{ onClick: () => history.push(ROUTE_PATHS[PAGES.ADMIN] + `/tickers/${ticker.id}`), disabled: true }}
					remove={{ onClick: (e) => e.stopPropagation(history.push(ROUTE_PATHS[PAGES.ADMIN] + `/tickers/${ticker.id}`)), disabled: true }}
				/>
			</tr>
		)
	});

	return (
		<Page fixedBanner showScrollbar>
			{/* Modal */}
			{showModal && (
				<RemoveConfirmModal
					label="ticker"
					handleClose={() => setShowModal(false)}
					cancelButton={{ onClick: () => setShowModal(false) }}
					confirmButton={{ onClick: () => setShowModal(false, toast('Ticker Removed')) }}
				/>
			)}
			<PageContentBanner divider gap="l">
				<Button disabled style={{minWidth: (width > 1240 ? 146 : "")}} icon={<Plus />}>{width < 900 ? "" : "Add Ticker"}</Button>
				<TickerListSearchField
					size="small"
					width="m"
					placeholder="Try searching AIA.NZ"
					onChange={setTickerSearchTerm} 
					value={tickerSearchTerm}
					onClear={() => setTickerSearchTerm("")} 
					tickerListOverwrite={tickers.map(t => { return {label: t.ric, value: t.ric}})}
					isClearable 
					autoFocus
					tickerListLoading={tickersLoading}
				/>
				{
					width > 1244 && (
						<>
							<SelectSearchField
								size="small"
								width="m"
								placeholder="Filter by exchange"
								isClearable
								noIcon
								options={exchanges && exchanges.map((exchange => {return {value:exchange.id, label: exchange.name, fullDTO: exchange }}))}
								onChange={(e) => setExchangeSearchTerm(e)}
								value={exchangeSearchTerm}
								onClear={() => setExchangeSearchTerm(null)}
							/>
							<SelectSearchField
								size="small"
								width="m"
								placeholder="Filter by sector"
								isClearable
								noIcon
								options={sectors && sectors.map(toValueLabelPair)}
								onChange={(e) => setSectorSearchTerm(e)}
								value={sectorSearchTerm}
								onClear={() => setSectorSearchTerm(null)}
							/>
						</>
					)
				}
			</PageContentBanner>
			<PageContentBodyGrid rowGap="xl" showScrollbar>
				{showRecentlyAdded && (
					<PageGridItem colSpan="12">
						<DetailSectionHeader icon={<TrendingUp />} header="Recently Added" />
					</PageGridItem>
				)}
				<PageGridItem colSpan="12">
					{/* Suggestion for initial page load */}
					{showRecentlyAdded || tickerSearchTerm || exchangeSearchTerm || sectorSearchTerm ? (
						<Table 
						id="tickers-table"
						minWidth={900} 
						colWidths={colWidths} 
						headers={headers} 
						dataMap={dataMap(tickerSearchTerm || exchangeSearchTerm || sectorSearchTerm ? filteredTickers : tickers)} 
						dataLoading={tickersLoading}
						/>
					) : (
						<SuggestionMessage>
							Start typing to see results or{" "}
							<span className="text-link" onClick={() => setShowRecentlyAdded(true)}>
								click here
							</span>{" "}
							to see recently added
						</SuggestionMessage>
					)}
					{/* Empty state message*/}
					{/* <NoResultsMessage /> */}
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default TickerList;