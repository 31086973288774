import React, { useState } from "react";
import { PageContentBodyGrid } from "../Shared/Page/Page";
import PageGridItem from "../Shared/Page/PageGridItem";
import RequestsLibraryTable from "./RequestsLibraryTable";
import LoadingIcon from "../../components/LoadingIcon";

const RequestsLibraryPageBody = ({filters}) => {
	const [requestsLoading, setRequestsLoading] = useState(true)

	return (
		<PageContentBodyGrid id="craigs-library-page-body" showScrollbar style={{paddingLeft: "0.5rem"}}>
			<PageGridItem fullWidth showScrollbar>
				{requestsLoading && <LoadingIcon centered className="mt-xl" />}
				<div style={requestsLoading ? { display: "none" } : null}>
					<RequestsLibraryTable setRequestsLoading={setRequestsLoading} filters={filters}/>
				</div>
			</PageGridItem>
		</PageContentBodyGrid>
	);

}

export default RequestsLibraryPageBody;