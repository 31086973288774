import React from "react";
import { ChevronDown, ChevronUp } from 'react-feather';
import './NumberInputWithStepper.scss'

const NumberInputWithStepper = ({ inputClassName, value, onChange, width }) => {
    function updateValue(val) {
        onChange && onChange(val && val.target ? val.target.value : val)
    }
    return (
        <div className='input-with-stepper' style={{ width: width }}>
            <input type='number' className={inputClassName} value={value} onChange={updateValue} style={{ width: width }}/>
            <Stepper passedNumber={value} setNumber={updateValue} min={0} max={100} />
        </div>
    )
}
export default NumberInputWithStepper


export const Stepper = ({ passedNumber, setNumber, min, max, horizontal }) => {
    function getNumber(number, direction) {
        if (direction === 'up') return max && +number === max ? min : +number + 1
        if (direction === 'down') return (min || min === 0) && +number === min ? max : +number - 1
    }
    return (
        <div className='stepper-container' style={{ flexDirection: horizontal ? 'row' : 'column' }}>
            <ChevronUp size={16} color='#999DAD' onClick={() => setNumber(getNumber(passedNumber, 'up'))} />
            <ChevronDown size={16} color='#999DAD' onClick={() => setNumber(getNumber(passedNumber, 'down'))} />
        </div>
    )
}