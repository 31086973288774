import React, { useEffect, useState } from 'react';
import { Plus, Trash2, Search, MoreHorizontal } from 'react-feather';
import useWindowDimensions, { useRemote, useStorage } from '../../../Utils/Utils';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp'
import Table from '../../../components/Table/Table';
import PageGridItem from '../../Shared/Page/PageGridItem';
import DetailField from '../../Shared/DetailSection/DetailField';
import Button from '../../../components/Buttons/Button';
import SimpleSearchFilter, { filterBy } from '../../../components/Input/SimpleSearchFilter';
import FlexWrapper from '../../FlexWrapper';
import Checkbox from '../../../components/Checkbox';
import Divider from '../../../components/Divider';
import DropDown from '../../../components/DropDown';
import withRouteChange from '@threeskye/route-change'
import AssetActionsPopup from './AssetActionsPopup';
import Tooltip from '../../../components/popups/Tooltip';
import ImageEditModal from '../../../components/Modals/ImageEditModal';
import '../ModelPortfolio/ModelPortfolioPage.scss'


const AddAssetClass = ({
    changeRoute, setAddMode, assetClassName, setAssetClassName, assetsList, classId, setData, classesList,
    imageUpload, setImageUpload, showImageEditModal, setShowImageEditModal, setEditedAsset,
    onDrop, setStagedImage, refreshClassData }) => {
    const remote = useRemote()
    const storage = useStorage()
    const { width } = useWindowDimensions()
    const dropdownOptions = ['Uncategorised', 'Categorised', 'All asssets']

    const [loading, setLoading] = useState(true)
    const [tickers, setTickers] = useState(null)
    const [updatedClass, setUpdatedClass] = useState(null)
    const [rawList, setRawList] = useState([])
    const [classesNamesObject, setClassesNamesObject] = useState(null)
    // Left side page states:
    const [newAssetClassName, setNewAssetClassName] = useState('')
    const [assetsForNewClass, setAssetsForNewClass] = useState([])
    const [leftSideSearchTerm, setLeftSideSearchTerm] = useState('')
    // Right side page states:
    const [originalUnselectedAssetsList, setOriginalUnselectedAssetsList] = useState([])
    const [unselectedAssetsList, setUnselectedAssetsList] = useState([])
    const [rightSideSearchTerm, setRightSideSearchTerm] = useState('')
    const [selectedAssets, setSelectedAssets] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [dropdownSelected, setDropdownSelected] = useState('All assets')

    useEffect(() => {
        if (classesList && classesList.length > 0) {
            const classObj = classesList.reduce((a, c) => ({ ...a, [c.id]: c }), {})
            setClassesNamesObject(classObj)
        }
    }, [classesList])

    useEffect(() => {
        storage.getOrFetch('/crm/tickers').then((fetchedTickers) => {
            const tickersObj = fetchedTickers.reduce((a, c) => ({ ...a, [c.isin]: c }), {})
            setTickers(tickersObj)
            getFreshData()
            if (assetClassName) setNewAssetClassName(assetClassName)
            if (assetsList) setAssetsForNewClass(assetsList.sort((a, b) => a.name.localeCompare(b.name)))
        })
    }, [assetsList])

    function getFreshData(assetsToExclude) {
        remote.get('assets/list').then((resp) => {
            if (assetsList) {
                const filteredList = resp.filter(a => a.assetClassId !== classId)
                const reFilteredList = getFilterList(dropdownSelected, filteredList).sort((a, b) => a.name.localeCompare(b.name))
                setUnselectedAssetsList(reFilteredList)
                setOriginalUnselectedAssetsList(filteredList)
                setLoading(false)
            } else {
                const listToFilter = assetsToExclude ? resp.filter(a => !assetsToExclude.includes(a.id)) : resp
                const filteredList = getFilterList(dropdownSelected, listToFilter).sort((a, b) => a.name.localeCompare(b.name))
                setUnselectedAssetsList(filteredList)
                setOriginalUnselectedAssetsList(filteredList)
                setOriginalUnselectedAssetsList(resp)
                setLoading(false)
            }
            setRawList(resp)
        });
    }

    function filter(dataToFilter, searchTerm) {
        if (searchTerm === null || searchTerm.trim().size === 0) {
            return dataToFilter
        }
        return dataToFilter.filter((datum) => filterBy(datum, ['name', 'code'], searchTerm))
    }

    function saveChanges() {
        if (assetClassName) {
            let payload = updatedClass
            if (assetClassName !== newAssetClassName && !updatedClass) {
                payload = { id: classId, name: newAssetClassName, assets: assetsForNewClass }
            }
            remote.put(`/assets/classes/${payload.id}`, payload).then((resp) => {
                getFreshData()
                setAssetsForNewClass([])
                setNewAssetClassName('')
                setAddMode(false)
                setNewAssetClassName(resp.name)
                setAssetsForNewClass(resp.assets)
                setData(resp.assets)
                setAssetClassName(resp.name)
                setUpdatedClass(null)
            })
        } else {
            const payload = { name: newAssetClassName, assets: assetsForNewClass }
            remote.post(`/assets/classes`, payload).then(resp => {
                changeRoute(`${ROUTE_PATHS[PAGES.ADMIN]}/asset-class/${resp.id}`)
                setAssetsForNewClass([])
                setNewAssetClassName('')
                setAddMode(false)
            })
        }
    }

    function cancelChanges() {
        const sortedUncategorised = assetsForNewClass.length > 0 ? [...unselectedAssetsList, ...assetsForNewClass].sort((a, b) => {
            const asset1 = a.code || '-'
            const asset2 = b.code || '-'
            return asset1.localeCompare(asset2)
        }) : unselectedAssetsList

        setUnselectedAssetsList(sortedUncategorised)
        setAssetsForNewClass([])
        setNewAssetClassName('')
        setSelectedAssets([])
        setUnselectedAssetsList(originalUnselectedAssetsList)
        setUpdatedClass(null)
        setAddMode(false)
    }

    function addAssets() {
        const listToUse = filter(unselectedAssetsList, rightSideSearchTerm)
        const filteredList = listToUse.filter(a => selectedAssets.includes(a.id))
        const newList = assetsForNewClass.length > 0 ? [...assetsForNewClass, ...filteredList] : filteredList
        const sortedList = newList.sort((a, b) => a.name.localeCompare(b.name))
        setAssetsForNewClass(sortedList)
        setUnselectedAssetsList(unselectedAssetsList.filter(a => !selectedAssets.includes(a.id)))
        setSelectedAssets([])
        setSelectAll(false)

        setUpdatedClass({
            id: classId,
            name: newAssetClassName,
            assets: sortedList
        })
    }

    function removeAsset(id) {
        const foundCode = assetsForNewClass.find(a => a.id === id)
        const newList = assetsForNewClass.filter(a => a.id !== id)
        const sortedUncategorised = [...unselectedAssetsList, foundCode].sort((a, b) => a.name.localeCompare(b.name))
        setUnselectedAssetsList(sortedUncategorised)
        setAssetsForNewClass(newList)

        setUpdatedClass({
            id: classId,
            name: newAssetClassName,
            assets: newList
        })
    }

    function shallowRemove(id) {
        const newList = assetsForNewClass.filter(a => a.id !== id)
        const asstesToExclude = newList.map(a => a.id)
        getFreshData(asstesToExclude)
        setAssetsForNewClass(newList)
    }

    function handleSelect(id) {
        if (selectedAssets.length > 0) {
            if (selectedAssets.includes(id)) setSelectedAssets(selectedAssets.filter(assetId => assetId !== id))
            else setSelectedAssets([...selectedAssets, id])
        } else setSelectedAssets([id])
    }

    function getFilterList(tag, data) {
        const list = tag === 'uncategorised' ? data.filter(a => a && !a.assetClassId) : tag === 'categorised' ? data.filter(a => a.assetClassId) : data
        return list
    }

    function getAssetsList(tag) {
        setDropdownSelected(tag)
        const list = getFilterList(tag, originalUnselectedAssetsList)
        setUnselectedAssetsList(list)
    }

    const assetsColWidth = ['auto', '3rem', '2.5rem']
    const assetsDataMap = (dataToMap) => {
        return dataToMap.map((asset) => {
            const { name, code, id, logoUploaded } = asset
            const foundTicker = asset.code && tickers[code] || null
            return (
                <tr className='tr-borders cursor-pointer' key={name + '-' + code}>
                    <td className='model-portfolio-class-name' style={{ paddingLeft: '0.5rem' }}>
                        <FlexWrapper align='center'  >
                            {logoUploaded ?
                                <img src={`/api/public/images/asset/${id}.png`} style={{ width: 40 }} alt="logo" /> : foundTicker && foundTicker.logo ?
                                    <img src={foundTicker.logo} style={{ width: 40 }} alt="logo" /> :
                                    <div className='logo-replacement'>{name.charAt()}</div>
                            }
                            <Tooltip label={name} disabled={!name || name.length < 20}>
                                <div style={{ maxWidth: 300 }} className='model-portfolio-ticker-name'>{name || 'N/A'}</div>
                            </Tooltip>
                            <span className='model-portfolio-ticker-detail-name'>{foundTicker?.ric || code}</span>
                        </FlexWrapper>
                    </td>
                    <td><Trash2 className='delete-icon' onClick={() => removeAsset(id)} /></td>
                    <td>
                        <Tooltip placement='bottom-end' padding='none' label={
                            <AssetActionsPopup
                                move
                                edit
                                classes={classesList.filter(c => c.id !== asset.assetClassId)}
                                asset={asset}
                                asetClassId={classId}
                                setImageUpload={setImageUpload}
                                setShowImageEditModal={setShowImageEditModal}
                                setEditedAsset={setEditedAsset}
                                allAssets={rawList}
                                newClass
                                callbackFunction={assetsList ? refreshClassData : null}
                                moveCallback={(id, classId) => { shallowRemove(id, classId) }}
                            />
                        } ><MoreHorizontal className='assets-actions-icon' />
                        </Tooltip>
                    </td>
                </tr>
            )
        })
    }

    const uncategorisedColWith = ['2rem', '330px', 'auto', '2.5rem']
    const uncategorisedDataMap = (dataToMap) => {
        return dataToMap.map((asset) => {
            const { name, code, id, logoUploaded } = asset
            const foundTicker = asset.code && tickers[code] || null
            return (
                <tr className='tr-borders cursor-pointer' key={name + '-' + id + '-' + code}>
                    <td>
                        <Checkbox checked={selectedAssets.length > 0 && selectedAssets.includes(id)}
                            onChange={() => handleSelect(id)}
                            className='delete-icon no-margin'
                        />
                    </td>
                    <td className='model-portfolio-class-name' style={{ paddingLeft: '0.5rem' }}>
                        <FlexWrapper align='center'  >
                            {logoUploaded ?
                                <img src={`/api/public/images/asset/${id}.png`} style={{ width: 40 }} alt="logo" /> : foundTicker && foundTicker.logo ?
                                    <img src={foundTicker.logo} style={{ width: 40 }} alt="logo" /> :
                                    <div className='logo-replacement'>{name.charAt()}</div>
                            }
                            <Tooltip label={name} disabled={!name || name.length < 20}>
                                <div className='model-portfolio-ticker-name'>{name || 'N/A'}</div>
                            </Tooltip>
                            <span className='model-portfolio-ticker-detail-name'>{foundTicker?.ric || code}</span>
                        </FlexWrapper>
                    </td>
                    <td className='text-align-right'>
                        <Tooltip label={classesNamesObject[asset.assetClassId]?.name} disabled={classesNamesObject[asset.assetClassId]?.name && classesNamesObject[asset.assetClassId]?.name.length > 30}>
                            <div className='model-portfolio-ticker-detail-name truncate pr-m'>{classesNamesObject[asset.assetClassId]?.name || ""}</div>
                        </Tooltip>
                    </td>
                    <td>
                        <Tooltip placement='bottom-start' padding='none' label={
                            <AssetActionsPopup
                                move
                                edit
                                classes={classesList.filter(c => c.id !== asset.assetClassId)}
                                asset={asset}
                                asetClassId={classId}
                                setImageUpload={setImageUpload}
                                setShowImageEditModal={setShowImageEditModal}
                                setEditedAsset={setEditedAsset}
                                allAssets={rawList}
                                callbackFunction={getFreshData}
                            />
                        } ><MoreHorizontal className='assets-actions-icon' />
                        </Tooltip>
                    </td>
                </tr>
            )
        })
    }

    return (
        <Page fixedBanner>
            <PageContentBanner divider gap='m'>
                <Button variant='secondary' onClick={() => cancelChanges()}>Cancel</Button>
                <Button disabled={newAssetClassName.length === 0 || (!updatedClass && newAssetClassName === assetClassName)} icon={<Plus />} onClick={() => saveChanges()}>{'Save Changes'}</Button>
            </PageContentBanner>

            <PageContentBodyGrid id='asset-class-management' rowGap='xl' gridTemplateRows='1fr' splitScroll>
                <PageGridItem container col='1 / span 6' style={{ paddingTop: 0 }}>
                    <FlexWrapper align='center' gap='m' className='pt-l pb-m' style={{ backgroundColor: '#fff', position: 'fixed', width: 'calc(50% - 13.5rem)' }}>
                        <DetailField fullWidth editMode={true} size='small' style={{ width: '100%' }} placeholder='Asset Class Name' value={newAssetClassName} onChange={(e) => setNewAssetClassName(e.target.value)} />
                        <Divider vertical height='32px' />
                        <SimpleSearchFilter
                            size='small'
                            fullWidth
                            placeholder={`Filter list`}
                            isClearable
                            icon={<Search />}
                            setSearchTerm={setLeftSideSearchTerm}
                            searchTerm={leftSideSearchTerm}
                            disabled={assetsForNewClass.length === 0}
                        />
                    </FlexWrapper>
                    <FlexWrapper align='center' gap='m' style={{ paddingTop: '4.5rem' }}>
                        {assetsForNewClass.length > 0 ?
                            <Table
                                minWidth={450}
                                colWidths={assetsColWidth}
                                dataMap={assetsDataMap(filter(assetsForNewClass, leftSideSearchTerm))}
                                dataLoading={loading}
                            />
                            : <div className='helper-text'>There are no assets in this Class. Add assets.</div>
                        }
                    </FlexWrapper>
                </PageGridItem>
                <PageGridItem container col='7 / span 6'>
                    <PageContentBodyGrid columnGap='s' rowGap='m' gridColCount='6' subGrid divider={width < 1200 ? 'top' : 'left'} paddingBottom={'3rem'}>
                        <PageGridItem col='1 / span 6' align='center' style={{ paddingTop: 0, backgroundColor: '#fff', position: 'fixed', width: 'calc(50% - 15rem)' }}>
                            <FlexWrapper align='center' gap='m' className='pb-m' style={{ borderBottom: '1px solid #E7EAF2' }}>
                                <DropDown value={dropdownSelected} classNames='header-dropdown text-transform-capitalise' clickable /*minMenuWidth={144}*/ minDropdownWidth={144} fixed>
                                    {dropdownOptions.map(o => <li key={o.name + '-' + o.id} onClick={() => getAssetsList(o)}>{o}</li>)}
                                </DropDown>
                                <SimpleSearchFilter
                                    size='small'
                                    fullWidth
                                    placeholder={`Filter list`}
                                    isClearable
                                    icon={<Search />}
                                    setSearchTerm={setRightSideSearchTerm}
                                    searchTerm={rightSideSearchTerm}
                                />
                            </FlexWrapper>
                            <FlexWrapper align='center' justify='space-between' className='pb-m pt-m'>
                                <Checkbox
                                    label={selectAll ? 'Deselect All' : 'Select All'}
                                    checked={selectAll}
                                    onChange={() => {
                                        if (selectAll) {
                                            setSelectedAssets([])
                                            setSelectAll(false)
                                        } else {
                                            const listToUse = filter(unselectedAssetsList, rightSideSearchTerm)
                                            setSelectedAssets(listToUse.map(a => a.id))
                                            setSelectAll(true)
                                        }
                                    }}
                                    className='pl-s'
                                />
                                <Button style={{ padding: '0.25rem' }} disabled={selectedAssets.length === 0} small onClick={() => addAssets()} icon={<Plus size={16} />}>Add Selected</Button>
                            </FlexWrapper>
                        </PageGridItem>
                        <PageGridItem showScrollbar container col='1 / span 6' style={{ paddingTop: '7.5rem', paddingBottom: '1rem' }}>
                            <Table
                                minWidth={450}
                                colWidths={uncategorisedColWith}
                                dataMap={uncategorisedDataMap(filter(unselectedAssetsList, rightSideSearchTerm))}
                                dataLoading={loading}
                            />
                        </PageGridItem>
                    </PageContentBodyGrid>
                </PageGridItem>
            </PageContentBodyGrid >
            {showImageEditModal && (
                <ImageEditModal
                    handleClose={() => setShowImageEditModal(null)}
                    cancelButton={{ onClick: () => { setShowImageEditModal(null) } }}
                    initialImg={imageUpload}
                    clearInitialImg={() => setImageUpload(null)}
                    onConfirm={(image) => {
                        onDrop(image, getFreshData)
                        setStagedImage(image);
                        setShowImageEditModal(null)
                    }}
                />
            )}
        </Page >
    );
}

export default withRouteChange(AddAssetClass);