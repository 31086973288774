import React, { useEffect, useRef, useState } from 'react';
import FlexWrapper from '../../layouts/FlexWrapper';
import StatusToken from '../StatusTokens';
import { format, formatDistance, parseISO, subDays, isAfter } from 'date-fns';
import './CommentsSection.scss';
import Button from '../Buttons/Button';
import TextArea from '../Input/TextArea';
import { useRemote, useStorage } from '../../Utils/Utils';

const CommentsSection = ({ ticker, templateName, workflowStatusId, requestId }) => {

	const [showReplies, setShowReplies] = useState([])
	const [showReplyInput, setShowReplyInput] = useState(null)
	const [commentInputValue, setCommentInputValue] = useState("")
	const [replyInputValue, setReplyInputValue] = useState("")
	const [comments, setComments] = useState([])
	const [authors, setAuthors] = useState([])
	const [me, setMe] = useState({})

	const remote = useRemote()
	const storage = useStorage()
	const perms = me?.perms || []
	const userRole = perms.includes("COMMENTS_PEER") ? "Peer" : perms.includes("COMMENTS_COMPLIANCE") ? "Compliance" : perms.includes("COMMENTS_SUPERVISOR") ? "Supervisor" : null

	const replyInputRef = useRef();

	const parseDate = (date) => {
		if (!date) {
			return ""
		}
		try {
			const parsedDate = parseISO(date)
			return handleDateFormatting(parsedDate)
		} catch {
			return handleDateFormatting(date)
		}

	}

	const handleDateFormatting = (date) => {
		const oneDayprevious = subDays(new Date(), 1)
		const dateIslessThanOneDayOld = isAfter(date, oneDayprevious)
		if (dateIslessThanOneDayOld) {
			return formatDistance(date, new Date())
		} else {
			return format(date, "dd MMM yyyy h:mm aaa")
		}
	}

	const hideShowReplies = (id) => {
		if (showReplies.includes(id)) {
			setShowReplies(showReplies.filter(replyId => replyId !== id))
		} else {
			setShowReplies([...showReplies, id])
		}
		setReplyInputValue("")
	}

	const showHideReplyInput = (id) => {
		if (showReplyInput === id) {
			setShowReplyInput(null)
		} else {
			setShowReplyInput(id)
		}
	}

	useEffect(() => {
		if (showReplyInput) {
			replyInputRef.current.focus()
		}
	}, [showReplyInput])

	useEffect(() => {
		storage.getOrFetch('/crm/users').then((val) => {
			setAuthors(val);
		});
		storage.getOrFetch('/crm/me').then(setMe)
	}, [])

	useEffect(() => {
		// fetch comments
		if (!ticker) return;
		if (!templateName) return;

		const identifier = btoa(ticker);
		let url = `/comments/${identifier}/${templateName}`
		
		const params = new URLSearchParams();
		if (requestId) params.append("requestId", requestId);
		if (workflowStatusId) params.append("workflowStatusId", workflowStatusId);

		if (params.toString()) {
			url += `?${params.toString()}`;
		}
		remote.get(url).then((val) => {
			const fetchedComments = []

			val.forEach(comment => {
				if (!comment.replyTo) {
					fetchedComments.push(comment)
				} else {
					const parentComment = fetchedComments.find(c => c.commentId === comment.replyTo)
					if (!parentComment.replies) parentComment.replies = [comment]
					else parentComment.replies.push(comment)
				}
			})
			setComments(fetchedComments)
		})
	}, [ticker, templateName])


	const handleCommentSubmit = (commentId) => {
		const DTO = {
			replyTo: commentId,
			authorRole: userRole,
			comment: commentId ? replyInputValue : commentInputValue,
		}

		const identifier = btoa(ticker);

		let url = `/comments/${identifier}/${templateName}`
		const params = new URLSearchParams();
		if (requestId) params.append("requestId", requestId);
		if (workflowStatusId) params.append("workflowStatusId", workflowStatusId);

		if (params.toString()) {
			url += `?${params.toString()}`;
		}
		remote.post(url, DTO)
			.then((response) => {
				if (commentId) {
					let newComments = comments.map(comment => {
						if (comment.commentId === commentId) {
							if (!comment.replies) comment.replies = [response.data]
							else comment.replies.push(response.data)
						}
						return comment
					})
					setComments(newComments)
					setReplyInputValue("")
					setShowReplyInput(null)
				} else {
					let newComments = [...comments, response.data]
					setComments(newComments)
					setCommentInputValue("")


				}
			}
			)
	}

	const getAuthor = (email) => {
		if (!authors || !email) return "Unknown"
		const author = authors.find(author => author.email === email)
		return author ? author.name : "Unknown"
	}
	const getAuthorPhoto = (email) => {
		if (!authors || !email) return null
		const author = authors.find(author => author.email === email)
		return author ? author.photo : null
	}

	return (
		<>
			<FlexWrapper direction="column" className="comments-input-container" align="start">
				<TextArea
					placeholder="Add a comment"
					className="add-comment-text-area"
					value={commentInputValue}
					onChange={(e) => setCommentInputValue(e.target.value)}
				/>
				<Button size="small" disabled={!userRole || !commentInputValue} fullWidth onClick={() => handleCommentSubmit()} >Submit Comment</Button>
			</FlexWrapper >
			<FlexWrapper direction="column" className="comments-outer-container" align="start">
				{comments.map((comment, idx) => (
					<div key={comment.commentId + idx} className={`single-comment ${(idx + 1) === comments.length ? " last-comment" : ""} ${idx === 0 ? " first-comment" : ""}`}>
						<div className='comment-header'>
							{getAuthorPhoto(comment.author)
								? <img src={getAuthorPhoto(comment.author)} alt={getAuthor(comment.author)} className='comment-author-image' />
								: <div className="comment-author-image-initials">{getAuthor(comment.author).split(" ").map(val => val[0])}</div>
							}
							<div className="comment-header-info">
								<div className="comment-header-info-top-row">
									<h4 className='comment-author'>{getAuthor(comment.author)}</h4>
								</div>
								<div className="comment-header-info-bottom-row">
									<StatusToken type="primary" label={comment.authorRole} />
									<p className='comment-time'>{parseDate(comment.date)}</p>
								</div>
							</div>
							{/* <h2 className='comment-author'>{comment.author}</h2> */}
						</div>
						<p className='comment-message-body'>{comment.comment}</p>
						{comment.replies
							? <div className='comment-footer' onClick={() => hideShowReplies(comment.commentId)}>
								{comment.replies &&
									<span>{comment.replies.length > 1 ? comment.replies.length + " Replies" : "1 Reply"}</span>
								}
								<i className='material-icons' >{showReplies.includes(comment.commentId) ? "expand_less" : "expand_more"}</i>
							</div>
							: <>

								<div className='comment-footer' onClick={() => showHideReplyInput(comment.commentId)}>
									{showReplyInput === comment.commentId ? "Hide" : "Reply"}
								</div>

								{showReplyInput === comment.commentId &&
									<FlexWrapper direction="column" className="comments-input-container" align="start">
										<TextArea
											placeholder="Add a comment"
											className="add-comment-text-area"
											value={replyInputValue}
											onChange={(e) => setReplyInputValue(e.target.value)}
											maxlength={200}
											forwardRef={replyInputRef}
										/>
										<Button size="small" disabled={!userRole || !replyInputValue} fullWidth onClick={() => handleCommentSubmit(comment.commentId)} >Submit Reply</Button>
									</FlexWrapper >
								}
							</>
						}

						{showReplies.includes(comment.commentId) && comment.replies.map((reply, replyIdx) => (
							<div className='single-reply' key={reply.id + replyIdx}>
								<div className='reply-header'>
								{getAuthorPhoto(reply.author)
								? <img src={getAuthorPhoto(reply.author)} alt={getAuthor(reply.author)} className='comment-author-image' />
								: <div className="comment-author-image-initials">{getAuthor(reply.author).split(" ").map(val => val[0])}</div>
							}									<div className="reply-header-info">
										<div className="reply-header-info-top-row">
											<h4 className='reply-author'>{getAuthor(reply.author)}</h4>
										</div>
										<div className="reply-header-info-bottom-row">
											<StatusToken type="primary" label={reply.peer ? "Peer" : reply.compliance ? "Compliance" : ""} />
											<p className='reply-time'>{parseDate(reply.date)}</p>
										</div>
									</div>
								</div>
								<p className='reply-message-body'>{reply.comment}</p>
								{(replyIdx + 1) === comment.replies.length &&
									<div className='comment-footer' onClick={() => showHideReplyInput(comment.commentId)}>
										{showReplyInput === comment.commentId ? "Hide" : "Reply"}
									</div>
								}
								{(replyIdx + 1) === comment.replies.length && showReplyInput === comment.commentId &&
									<FlexWrapper direction="column" className="comments-input-container" align="start">
										<TextArea
											placeholder="Add a comment"
											className="add-comment-text-area"
											value={replyInputValue}
											onChange={(e) => setReplyInputValue(e.target.value)}
											maxlength={200}
											forwardRef={replyInputRef}
										/>
										<Button size="small" disabled={!userRole} fullWidth onClick={() => handleCommentSubmit(comment.commentId)} >Submit Reply</Button>
									</FlexWrapper >
								}
							</div>
						))}
					</div>
				))}
			</FlexWrapper>
		</>
	);
}

export default CommentsSection;