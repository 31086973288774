import React, { useState, useEffect } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import useWindowDimensions, { useRemote } from '../../../Utils/Utils';
import { useParams, useHistory } from 'react-router-dom';
import TemplateGeneralDetails from './TemplateGeneralDetails';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import TemplateSettingsList from './TemplateSettingsList'
import TemplateOptions from './TemplateOptions';
import MiniSectorsList from '../../Shared/TablesAndLists/MiniSectorsList';

const actionTypeLabels = {
	compliance: "Compliance Actions",
	publish: "Publish Actions",
	custom: "Custom Publish Actions",
	data: "Data Actions"
}

const TemplateMgmt = ({ me }) => {
	const [template, setTemplate] = useState({});
	const [originalTemplate, setOriginalTemplate] = useState({})
	const [selectedPublishActions, setSelectedPublishActions] = useState([]);
	const [originalSelectedActions, setOriginalSelectedActions] = useState([])
	const [templateDef, setTemplateDef] = useState({});
	const [notFound, setNotFound] = useState(false);
	const [assetType, setAssetType] = useState({ label: 'Sector', value: 'Sector' })
	const [editMode, setEditMode] = useState(false)
	const [actionables, setActionables] = useState(false)
	const actionableCategories = Object.keys(actionables)
	const [changedParams, setChangedParams] = useState([])
	const [invalidParams, setInvalidParams] = useState({})

	const remote = useRemote();
	const history = useHistory()
	const { width } = useWindowDimensions();
	const { template: templateId } = useParams()

	useEffect(() => {
		remote.get("/crm/templates/" + templateId).then((fetchedTemplate) => {
			setOriginalTemplate(fetchedTemplate)
			setTemplate(fetchedTemplate)
		}).catch(e => {
			console.error("Caught e ", e);
			setNotFound(true);
		});
		remote.get(`/crm/templates/${templateId}/publish-steps`).then((fetchSelectedActions) => {
			setOriginalSelectedActions(fetchSelectedActions)
			setSelectedPublishActions(fetchSelectedActions)
		});
		remote.get(`/crm/publish-steps`).then((actionables) => {

			let categories = {}
			actionables.forEach(action => {
				if (!categories[action.type]) categories[action.type] = []
				categories[action.type].push(action)
			})
			setActionables(categories)
		});

	}, []);


	useEffect(() => {
		if (template && template.template) {
			try {
				setTemplateDef(JSON.parse(template.template))
			} catch (e) {
				console.error(e)
			}
		}
	}, [template])


	function addActionToTemplate(action) {
		let newActions = selectedPublishActions.map(a => ({ ...a })); // Create a deep copy of the array
		let actionIndex = newActions.findIndex(a => a.stepId === action.id);
		if (actionIndex !== -1) {
			newActions[actionIndex].active = !newActions[actionIndex].active;
			setSelectedPublishActions(newActions);
		}
	}

	function removeActionFromTemplate(action) {
		let newActions = selectedPublishActions.map(a => ({ ...a })); // Create a deep copy of the array
		let actionIndex = newActions.findIndex(a => a.stepId === action.id);
		if (actionIndex !== -1) {
			newActions[actionIndex].active = !newActions[actionIndex].active;
			setSelectedPublishActions(newActions);
		}
		if (invalidParams[action.id]) {
			const newInvalidParams = { ...invalidParams };
			delete newInvalidParams[action.id];
			setInvalidParams(newInvalidParams);
		}
	}

	const handleParamsValidation = (actionName, isInvalid) => {
		if (isInvalid) {
			setInvalidParams({ ...invalidParams, [actionName]: isInvalid });
		} else {
			const newInvalidParams = { ...invalidParams };
			if (newInvalidParams[actionName]) {

				delete newInvalidParams[actionName];
				setInvalidParams(newInvalidParams);
			}
		}
	};

	function goBack() {
		history.push("/admin/templates")
	}

	function updateParams() {
		if (changedParams.length === 0) { 
			setOriginalSelectedActions(selectedPublishActions)
		} else {
			remote.put(`crm/templates/${templateId}/publish-steps/configs`, changedParams).then((response) => {
				setChangedParams([])
				setInvalidParams({})
				setOriginalSelectedActions(response)
				setSelectedPublishActions(response)
			})
		}
	}

	function saveEdits() {
		setEditMode(false)
		let changedSelections = selectedPublishActions.filter((action, idx) => { return action.active !== originalSelectedActions[idx].active})
		if (changedSelections.length === 0) updateParams()
		else {
			Promise.all(changedSelections.map((action, idx) => {
				const originalAction = originalSelectedActions.find(a => a.stepId === action.stepId)
				if (action.active !== originalAction.active) {
					if (action.active) remote.put(`/crm/templates/${templateId}/publish-steps/${action.stepId}`)
					else remote.delete(`crm/templates/${templateId}/publish-steps/${action.stepId}`)
				};
			})).then(() => {
				updateParams()
			})
		}
	}

	function onParamsChange(stepId, param, value) {
		let newParams = [...changedParams];
		let stepIndex = newParams.findIndex(step => step.stepId === stepId);

		if (stepIndex === -1) {
			newParams.push({ stepId: stepId, [param]: value });
		} else {
			newParams[stepIndex][param] = value;
		}

		setChangedParams(newParams);
	}


	function cancelEdits() {
		setTemplate(originalTemplate)
		setEditMode(false)
		setSelectedPublishActions(originalSelectedActions)
		setChangedParams([])
		setInvalidParams({})
	}

	if (notFound) return (
		<Page fixedBanner>
			<PageContentBanner divider>
				<ActionButtonsBanner />
			</PageContentBanner>
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				<p>Invalid template</p>
			</PageContentBodyGrid>
		</Page>
	)

	return (
		<Page fixedBanner>
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<ActionButtonsBanner
					handleBackOnClick={goBack}
					editMode={editMode}
					edit={{ onClick: () => setEditMode(true) }}
					cancel={{ onClick: () => { cancelEdits() } }}
					save={{ disabled: Object.keys(invalidParams).length > 0, onClick: () => { saveEdits() } }}
				/>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the template details section */}
				<PageGridItem container col="1 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
						{/* General Details */}
						<TemplateGeneralDetails template={templateDef} setTemplate={setTemplateDef} editMode={editMode} />
						<PageGridDivider col="1 / span 6" />
						{/* Settings Lists */}
						{actionableCategories.map((category, index) => {
							return <TemplateSettingsList
								key={index + "-template-settng-category"}
								templateId={templateId}
								templateName={template.name}
								data={actionables && actionables[category] ? actionables[category] : []}
								addActionToTemplate={addActionToTemplate}
								removeAction={removeActionFromTemplate}
								selected={selectedPublishActions}
								setSelectedPublishActions={setSelectedPublishActions}
								templateType={templateDef?.ticker?.templateType || "-"}
								editMode={editMode}
								category={category}
								onParamsChange={onParamsChange}
								changedParams={changedParams}
								setInvalidParams={handleParamsValidation}
								invalidParams={invalidParams}
							/>
						})}
					</PageContentBodyGrid>
				</PageGridItem>
				{/* grid container for the sectors list section */}
				<PageGridItem container col="7 / span 6">
					<PageContentBodyGrid showScrollbar rowGap="xl" gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"} paddingBottom={"3rem"}>
						<TemplateOptions template={templateDef} assetType={assetType} setAssetType={setAssetType} />
						{templateDef && templateDef.ticker && templateDef.ticker.options && (templateDef.ticker.type === 'selection' || templateDef.ticker.type === 'scheme') &&
							<>
								<PageGridItem col="1 / span 6">
									<PageGridDivider col="1 / span 6" />
								</PageGridItem>
								<PageGridItem col="1 / span 6">
									<MiniSectorsList data={templateDef.ticker && templateDef.ticker.options} assetType={assetType} stopRedirect />
								</PageGridItem>
							</>
						}
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default TemplateMgmt;