import React, { useState } from 'react';
import { withData } from "../../../DataController";
import { toastDanger, toastSuccess } from '../../popups/Toast';
import Checkbox from '../../Checkbox';
import SidebarExtensionWrapper from './SideBarExtensionWrapper';
import SideBarSection from './SideBarSection';
import TextInput from '../../Input/TextInput';
import Button from '../../Buttons/Button';
import "./OptionsSidebarExtension.scss";


const OptionsSidebarExtension = ({ slideIn, slideOut, template, optionalPages, setOptionalPages, loadingLocalData, data }) => {
	const [numRows, setNumRows] = useState([]);
	const [numCols, setNumCols] = useState([]);

	const includePage = (page) => {
		const index = optionalPages.indexOf(page);
		if (index > -1) {
			optionalPages.splice(index, 1);
		} else {
			optionalPages.push(page);
		}
		setOptionalPages(optionalPages);
		data.updateLocalData("OPTIONAL_PAGES", JSON.stringify(optionalPages), "SIDEBAR");
	}

	const setClipboard = (text) => {
		const type = "text/html";
		const blob = new Blob([text], { type });
		const data = [new window.ClipboardItem({ [type]: blob })];

		navigator.clipboard.write(data).then(
			() => { toastSuccess("Table copied, please paste into the editor") },
			() => { toastDanger("Copy failed, you might need to give permission to your browser") }
		);
	}

	const createTable = () => {
		if (!window.ClipboardItem) {
			toastDanger("Unfortunately your browser does not support copying to the clipboard");
			return;
		}
		let string = "<table>";
		for (let r = 0; r < (numRows || 2); r++) {
			let row = "<tr>";
			for (let c = 0; c < (numCols || 2); c++) {
				row += "<td width='10'></td>";
			}
			row += "</tr>";
			string += row;
		}
		string += "</table>";

		setClipboard(string);
	}


	return (
		<SidebarExtensionWrapper noPadding position="right" shadow slideIn={slideIn} slideOut={slideOut}>
			<div className="sidebar-section-header pl-m pr-m pb-l align-items-center display-md-fle justify-content-between"><h4>Template Options</h4></div>
			{template &&
				template.optionalPages &&
				template.pages
					.filter((page) => page.optional)
					.map((page, idx) => {
						return (
							<SideBarSection header="Optional Pages" separator>
								<Checkbox
									key={idx}
									label={"Include " + page.name}
									checked={optionalPages && optionalPages.includes(page.name)}
									onChange={() => includePage(page.name)}
									disabled={loadingLocalData}
								></Checkbox>
							</SideBarSection>
						);
					})}
			<SideBarSection header="Insert table" separator>
				<TextInput label="Number of Rows" onChange={(e) => setNumRows(e.target.value)} size="small" placeHolder={"Num Rows"} value={numRows} />
				<TextInput className="mt-xl mb-xl" label="Number of Columns" onChange={(e) => setNumCols(e.target.value)} size="small" placeHolder={"Num Cols"} value={numCols} />
				<Button classNames="back-to-home-button" rounded onClick={() => createTable()}>
					Copy table to clipboard
				</Button>
			</SideBarSection>
		</SidebarExtensionWrapper>
	);
}

export default withData(OptionsSidebarExtension);