import React from "react";
import { Search } from "react-feather";
import TextInput from './TextInput'
import _ from 'lodash'
/*
 * This module provides
 * 1. A simple search input
 * 2. A helper function that can be used to search multiple object values
 * 
 * This replaces SearchTermFilter
 */
const SimpleSearchFilter = ({ placeholder, size, width, isClearable, searchTerm, setSearchTerm, disabled, fullWidth, style }) => {

	return (
		<TextInput
			type="search"
			placeholder={placeholder}
			onChange={e => setSearchTerm(e.target.value)}
			size={size}
			width={width}
			icon={<Search />}
			clearText={() => setSearchTerm("")}
			isClearable={isClearable}
			value={searchTerm}
			disabled={disabled}
			fullWidth={fullWidth}
			style={style}
		>
		
		</TextInput>
	);
}

// extracts searchable string from the item
const extractSearchableString = (datum, path) => {
    let pathType = typeof path
    switch (pathType) {
        case "string":
            return datum[path]

        case "object":
            let searchableString = ""
            path.forEach((locater) => {
                let stringToAdd = _.get(datum, locater)
                searchableString = searchableString + ' ' + stringToAdd
            })
            return searchableString
        default:
            break;
    }
}

/*  This is a helper method that can filter data based on a list of object properties.
    Usually used in the same place the search filter is used.
*/
export const filterBy = (datum, path, searchTerm) => {
    let searchTermArray = searchTerm.toLowerCase().split(" ")

   // extractSearchableString(datum, path)
    let formattedDatum = extractSearchableString(datum, path).toLowerCase()
    let verify = searchTermArray.map((term) => {
        if (formattedDatum.includes(term)) {
            return true
        } else {
            return false
        }
    })
    if (verify.includes(false) === false) {
        return datum
    }
}

export default SimpleSearchFilter;
