import React, { Component } from "react";
import "./RadioInput.scss";

class RadioInput extends Component {
	render() {
		const { label, checked, name, onClick, invalid } = this.props;
		return (
			<div className="radio-input-field" onClick={onClick}>
				<i className={`material-icons ${invalid ? "invalid-radio-input" : ""}`}>{checked ? "radio_button_checked" : "radio_button_unchecked"}</i>
				<label htmlFor={name}>{label}</label>
			</div>
		);
	}
}

export default RadioInput;
