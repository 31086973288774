import React, { useState, useEffect, useRef } from 'react';
import { SketchPicker, GithubPicker } from 'react-color';
import { useOutsideAlerter, useRemote } from '../../../Utils/Utils';
import chroma from 'chroma-js';
import DropDown from '../../../components/DropDown';
import Button from '../../../components/Buttons/Button';
import Popup from '../../../components/Popup';
import _ from 'lodash'
import "./BrandStyles.scss"


export const fontWeights = ['Regular', 'Bold', 'Italic', 'Bold Italic']
export const sizes = ['6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '18', '23', '26', '30', '33', '36', '39']
export const dividerWidths = ['0', '0.5', '0.75', '1', '1.25', '1.5']

export const ColourSample = ({ colour, label, alternativeLabel, dontDisplay, changeBrandColour }) => {
    const [currentColour, setCurrentColour] = useState(colour);
    const [displayColourPicker, setDisplayColourPicker] = useState(false);
    const ref = useRef()

    const cancelColourChange = () => {
        setCurrentColour(colour)
        setDisplayColourPicker(false)
    }

    const outsideAlerter = useOutsideAlerter(ref, cancelColourChange);

    return (
        <div ref={ref} className='sample-container' style={{ display: dontDisplay ? 'none' : null, position: 'relative' }}>
            <div className='colour-sample' style={{ backgroundColor: colour }} onClick={() => setDisplayColourPicker(!displayColourPicker)}></div>
            <div>{alternativeLabel || label}</div>
            {displayColourPicker &&
                <div className='brand-style-colour-picker'>
                    <SketchPicker
                        styles={{
                            default: {
                                picker: {
                                    boxShadow: "0 0 0px rgba(0, 0, 0, 0.2)",
                                    borderRadius: "10px",
                                },
                                saturation: {
                                    borderRadius: "10px",
                                },
                                sliders: {
                                    padding: "10px",
                                },
                                swatch: {
                                    backgroundColor: "turquoise",
                                    borderRadius: "6px",
                                },
                            }
                        }}
                        color={currentColour}
                        onChangeComplete={(bits) => setCurrentColour(bits.hex)}
                        onChange={(bits) => setCurrentColour(bits.hex)}
                        disableAlpha
                        presetColors={["#FF6501", "#FFBD9A", "#010101", "#AFABAC", "#7F7F7F", "#595959", "#faf9f8", "#eeede9", "#2B2E34", "#807766", "#a09e8f", "#ffffff"]}
                    />

                    <div style={{ display: "flex", padding: "0.25rem 0.5rem", gap: "0.25rem", transformOrigin: "right", justifyContent: "flex-start" }}>
                        {/* <Button iconOnly variant="secondary" icon={<i className='material-icons'>delete</i>} onClick={cancelColourChange} /> */}
                        <Button small fullWidth onClick={() => { changeBrandColour(currentColour); setDisplayColourPicker(false); }} >Save</Button>
                    </div>
                </div>
            }
        </div>
    )
}

export const Typography = ({ label, details, updateDetails }) => {
    const [selected, setSelected] = useState({});
    const [family, setFamily] = useState(null)
    const [weight, setWeight] = useState(null)
    const [size, setSize] = useState(null)
    const [showColourList, setShowColourList] = useState(false)
    const [colour, setColour] = useState(null)
    const [coloursHex, setColoursHex] = useState([])
    const [availableFonts, setAvailableFonts] = useState([]);
    const [changes, setChanges] = useState(false);

    const ref = useRef()
    const remote = useRemote();

    useEffect(() => {
        if (!details || !details.colours) return;

        const colourList = Object.values(details.colours).sort((a1, a2) => a1.id - a2.id)
        const hexs = colourList && colourList.length > 0 && colourList.map(c => c.value)
        setColoursHex([...new Set(hexs)])

    }, [details])

    const update = () => {
        if (!details || !selected) {
            console.error("Trying to save with missing details or selected ", details, selected);
            return;
        }

        remote.put(`/brand/typography/${details.brandId}/${selected.id}`, {
            id: selected.id,
            name: selected.name,
            familyName: family,
            variantName: weight,
            size: size,
            colour: colour
        }).then(t => {
            setSelected(t);
            setFamily(t.familyName)
            setWeight(t.variantName);
            setSize(t.size);
            setColour(t.colour);

            const deets = { ...details };
            deets.typography[label] = t;
            updateDetails(deets);
        })
    }

    useEffect(() => {
        if (!details) return;

        const t = details.typography[label];
        setSelected(t);
        if (t) {
            setFamily(t.familyName)
            setWeight(t.variantName);
            setSize(t.size);
            setColour(t.colour);
        }

        const families = details.fonts.map(f => f.familyName);
        //de-dup
        setAvailableFonts(_.uniq(families));

    }, [details])

    useEffect(() => {
        setChanges(selected?.familyName !== family || selected?.variantName !== weight || selected?.size !== size || selected?.colour !== colour);
    }, [family, weight, size, colour, selected]);

    const revert = () => {
        if (!selected) return;
        setFamily(selected.familyName);
        setWeight(selected.variantName);
        setColour(selected.colour);
        setSize(selected.size);
    }

    if (!selected) return null;

    return (
        <div className='sample-group'>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <label className='colour-text-label-grey'>{label}</label>
                <Popup popupElement={<GithubPicker colors={coloursHex} onChangeComplete={(color) => setColour(color.hex)} />}>
                    <div
                        ref={ref}
                        className='typography-colour-indicator'
                        style={{ backgroundColor: colour }}
                        onClick={() => setShowColourList(!showColourList)} >
                    </div>
                </Popup>
            </div>
            {availableFonts &&
                <DropDown editMode={true} onChange={(e) => null} value={family}>
                    {availableFonts.map((fam, index) => <div key={index} onClick={() => setFamily(fam)}>{fam}</div>)}
                </DropDown>
            }
            <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '170px 103.5px' }}>
                <DropDown editMode={true} onChange={(e) => null} value={weight}>
                    {fontWeights.map((w, index) => <div key={index} onClick={() => setWeight(w)}>{w}</div>)}
                </DropDown>
                <DropDown editMode={true} onChange={(e) => null} value={size ? size + 'pt' : null}>
                    {sizes.map((s, index) => <div key={index} onClick={() => setSize(s)}>{s}pt</div>)}
                </DropDown>
            </div>
            <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '137px 137px' }}>
                <Button disabled={!changes} small onClick={update}>Save changes</Button>
                <Button disabled={!changes} small variant='secondary' onClick={revert}>Revert</Button>
            </div>
        </div>
    )
}

export const DividerWidth = ({ label, details, updateDetails }) => {
    const [selected, setSelected] = useState({});
    const [size, setSize] = useState(null)
    const [colour, setColour] = useState(null)
    const [changes, setChanges] = useState(false);
    const [coloursHex, setColoursHex] = useState([])
    const [showColourList, setShowColourList] = useState(false)

    const ref = useRef()
    const remote = useRemote();

    useEffect(() => {
        if (!details || !details.colours) return;
        const colourList = Object.values(details.colours).sort((a1, a2) => a1.id - a2.id)
        const hexs = colourList && colourList.length > 0 && colourList.map(c => c.value)
        setColoursHex([...new Set(hexs)])
    }, [details])

    const update = () => {
        if (!details || !selected) {
            console.error("Trying to save with missing details or selected ", details, selected);
            return;
        }

        remote.put(`/brand/divider/${details.brandId}/${selected.id}`, {
            id: selected.id,
            name: selected.name,
            size: size,
            colour: colour
        }).then(d => {
            setSelected(d);
            setSize(d.size);
            setColour(d.colour);
            const deets = { ...details };
            deets.dividers[label] = d;
            updateDetails(deets);
        })
    }

    useEffect(() => {
        if (!details) return;
        const t = details.dividers[label];
        setSelected(t);
        if (t) {
            setSize(t.size);
            setColour(t.colour);
        }
    }, [details])

    useEffect(() => {
        setChanges(selected?.size !== size || selected?.colour !== colour);
    }, [size, colour, selected]);

    const revert = () => {
        if (!selected) return;
        setColour(selected.colour);
        setSize(selected.size);
    }

    if (!selected) {
        return null;
    }

    return (
        <div className='sample-group'>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <label className='colour-text-label-grey'>{label}</label>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                    <Popup popupElement={<GithubPicker colors={coloursHex} onChangeComplete={(color) => setColour(color.hex)} />}>
                        <div
                            ref={ref}
                            className='typography-colour-indicator'
                            style={{ backgroundColor: colour }}
                            onClick={() => setShowColourList(!showColourList)} >
                        </div>
                    </Popup>
                    <DropDown editMode={true} onChange={(e) => null} value={size ? size + 'pt' : null} style={{ width: '88px' }} inputstyle={{ width: 84 }}>
                        {dividerWidths.map((s, index) => <div key={index} onClick={() => setSize(s)}>{s + 'pt'}</div>)}
                    </DropDown>
                </div>
            </div>
            <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '137px 137px' }}>
                <Button disabled={!changes} small onClick={update}>Save changes</Button>
                <Button disabled={!changes} small variant='secondary' onClick={revert}>Revert</Button>
            </div>
        </div>
    )
}

export function generateLighterColors(baseColor, lightestColor = 'white', count = 7) {
    const scale = chroma.scale([baseColor, lightestColor]).mode('lab');
    let colors = [];
    for (let i = 0; i < count; i++) {
        colors.push(scale(i / (count - 1)).hex());
    }
    return colors;
}

export function extractColours(array) {
    const allColoursObject = {};
    array.forEach(category => {
        category.values.forEach(value => {
            if (category.category === 'Tables') {
                if (value.borderBottomColor) allColoursObject[value.keyName] = value.borderBottomColor
                if (value.backgroundColor) allColoursObject[value.keyName + 'Background'] = value.backgroundColor
                if (value.color) allColoursObject[value.keyName + 'Text'] = value.color
            } else if (value.keyName && value.color) {
                allColoursObject[value.keyName] = value.color;
            }
        });
    });
    return allColoursObject;
}

export function extractBrandTypography(array) {
    const allColoursObject = [];
    array.forEach(category => {
        category.values.forEach(value => {
            if (value.keyName && value.fontFamily) {
                allColoursObject.push({ label: value.label, keyName: value.keyName, fontFamily: value.fontFamily, size: value.size + 'pt', weight: 'regular', color: value.color, lineHeight: 1.2 })
            }
        });
    });
    return allColoursObject;
}

export function extractTypographies(array) {
    const allColoursObject = {};
    array.forEach(category => {
        category.values.forEach(value => {
            if (value.keyName && value.fontFamily) {
                allColoursObject[value.keyName] = { fontFamily: value.fontFamily, size: value.size + 'pt', weight: 'regular', color: value.color, lineHeight: 1.2 }
            }
        });
    });
    return allColoursObject;
}

export const allCompaniesStyles = {
    craigs: {
        baseColour: '#ac724a',
        lightestColour: '#f9f6f3',

        logoInfo: {
            src: null,
            imageStyle: { width: 219 },
            imageContainerStyle: { width: 224, border: '1px solid #E7EAF2', padding: 1.5, height: 109 },
            recommendedSize: '219 x 104',
            logoInBanner: { backgroundColor: '#e7e6dd' }
        }

    },

    kernel: {
        baseColour: '#04796b',
        lightestColour: '#edf9f5',

        logoInfo: {
            src: null,
            imageStyle: { width: 187, height: 36 },
            imageContainerStyle: { width: 200, border: '1px solid #E7EAF2', padding: 5, height: 49 },
            recommendedSize: '187 x 36',
            logoInBanner: { backgroundColor: '#edf9f5', paddingTop: '2.5rem' }
        }
    },

    threeskye: {
        baseColour: '#0a8efd',
        lightestColour: '#ecf4ff',

        logoInfo: {
            src: null,
            imageStyle: { width: 152, height: 34 },
            imageContainerStyle: { width: 172, height: 54, padding: 10, border: '1px solid #E7EAF2', },
            recommendedSize: '152 x 35',
            logoInBanner: { backgroundColor: '#ffffff', paddingTop: '2.5rem' }
        }
    },

    simplicity: {
        baseColour: '#0a8efd',
        lightestColour: '#ecf4ff',

        logoInfo: {
            src: null,
            imageStyle: { width: 'auto', height: 100 },
            imageContainerStyle: { width: 98, height: 120, padding: 10, border: '1px solid #E7EAF2', },
            recommendedSize: '78 x 100',
            logoInBanner: { backgroundColor: '#edece8', paddingTop: '2.5rem' }
        }
    },

    colonial: {
        baseColor: '',
        lightestColour: '',
        logoInfo: {
            src: null,
            imageStyle: { width: 'auto', height: 100 },
            imageContainerStyle: { width: 255, height: 120, padding: 10, border: '1px solid #E7EAF2', },
            recommendedSize: '235 x 100',
            logoInBanner: { paddingTop: '2.5rem' },
            disclaimerLogowith: '74.25pt'
        }
    },

    hejaz: {
        baseColor: '',
        lightestColour: '',
        logoInfo: {
            src: null,
            imageStyle: { width: 'auto', height: 85 },
            imageContainerStyle: { width: 180, height: 105, padding: 10, border: '1px solid #E7EAF2', },
            recommendedSize: '160 x 85',
            logoInBanner: { paddingTop: '4rem', position: 'relative', top: -280 },
            bannerSrc: null, // HejazBanner,
            bannerHeight: 273,
            disclaimerLogowith: '90pt'
        }
    },

    north: {
        baseColor: '',
        lightestColour: '',
        logoInfo: {
            src: null,
            imageStyle: { width: 'auto', height: 85 },
            imageContainerStyle: { width: 208, height: 105, padding: 10, border: '1px solid #E7EAF2', },
            recommendedSize: '160 x 85',
            logoInBanner: { paddingTop: '4rem', position: 'relative', top: -170 },
            bannerSrc: null, // MyNorthBanner,
            bannerHeight: 125,
            disclaimerLogowith: '90pt'
        }
    }
}

export function getSubBrandName(string, string2) {
    switch (string) {
        case 'hejaz':
            return string2 === 'template' ?
                'Hejaz Fact Sheet' : string2 === 'email' ?
                    'www.admin@hejaz.com.au' : string2 === 'website' ?
                        'www.hejazfs.com.au' : string2 === 'legalName' ?
                            'Hejaz Asset Management Pty Ltd ABN 69 613 618 821 AFSL 550009' : string2 === 'phone' ?
                                '1300 043 529' : string2 === 'insurerLegalName' ?
                                    'AIA Australia Limited ABN 79 004 837 861 AFSL 230043 (AIA Australia)' : string2 === 'product' ?
                                        'Hejaz Islamic Super & Pension' :
                                        'Hejaz Asset Management'
        case 'north':
        case 'my-north':
            return string2 === 'template' ?
                'MyNorth Fact Sheet' : string2 === 'email' ?
                    'www.north@amp.com.au' : string2 === 'website' ?
                        'northonline.com.au' : string2 === 'legalName' ?
                            'NMMT Limited ABN 42 058 835 573 AFSL 234653 (NMMT) ' : string2 === 'phone' ?
                                '1800 667 841' : string2 === 'insurerLegalName' ?
                                    'TAL Life Limited ABN 70 050 109 450 AFSL 237848 (TAL)' : string2 === 'product' ?
                                        'MyNorth Super & Pension' :
                                        'MyNorth'
        case 'bulletin':
            return 'Bulletin'
        case 'pds':
            return 'Product Disclosure Statement'
        case 'sipo':
            return 'Statement of Investment Policy and Objectives'
        case 'qfu':
            return 'Quarterly Fund Update'
        default:
            return string2 === 'template' ?
                'CFS Bulletin' : string2 === 'website' ?
                    'www.cfs.com.au' : string2 === 'legalName' ?
                        'Colonial First State Investments Limited ABN 98 002 348 352 AFSL 232468 (CFSIL)' : string2 === 'phone' ?
                            '13 13 36' : string2 === 'insurerLegalName' ?
                                'AIA Australia Limited ABN 79 004 837 861 AFSL 230043 (AIA Australia)' : string2 === 'product' ?
                                    'FirstChoice super and pension' :
                                    'Colonial First State'
    }
}

export function getCategoryChartData(categoryChartConfig, companyId) {
    const dataObj = {
        "data": {
            "data": companyId === 2 ? {
                "TICKERLIST": "FND697",
                "FUND_NUMBER": "",
                "PUBLISH_DATE": "",
                "QUARTER_ENDING_DATE": "2024-06-30",
                "FUND_NAME": "Growth Fund",
                "FUND_VALUE": "2.30118791293E9",
                "FUND_STARTED_DATE": "2016-09-01",
                "FUND_DESCRIPTION": "The Growth Fund provides investors with a limited exposure to income assets, but most of its investments are in growth assets.  ",
                "RISK": "5",
                "CURRENT_YEAR": "2024",
                "RETURN_FUND[-6]": "0.0819",
                "RETURN_FUND[-5]": "0.0781",
                "RETURN_FUND[-4]": "-0.0259",
                "RETURN_FUND[-3]": "0.2741",
                "RETURN_FUND[-2]": "0.0322",
                "RETURN_FUND[-1]": "-0.0405",
                "RETURN_FUND[0]": "0.1604",
                "RETURN_FUND[1]": "0.0768",
                "RETURN_BENCHMARK[-6]": "0.0997",
                "RETURN_BENCHMARK[-5]": "0.096",
                "RETURN_BENCHMARK[-4]": "-0.0101",
                "RETURN_BENCHMARK[-3]": "0.296",
                "RETURN_BENCHMARK[-2]": "0.0391",
                "RETURN_BENCHMARK[-1]": "-0.0363",
                "RETURN_BENCHMARK[0]": "0.1748",
                "RETURN_BENCHMARK[1]": "0.0908",
                "ANNUAL_RTN_AFTER_FEES_AND_TAX_AVG_5_YEAR": "0.1197",
                "ANNUAL_RTN_AFTER_FEES_AND_TAX_PAST_YEAR": "0.0682",
                "ANNUAL_RTN_AFTER_FEES_BEFORE_TAX_AVG_5_YEAR": "0.0786",
                "ANNUAL_RTN_AFTER_FEES_BEFORE_TAX_PAST_YEAR": "0.1329",
                "ANNUAL_RTN_MKT_BEFORE_FEES_AND_TAX_AVG_5_YEAR": "0.079",
                "ANNUAL_RTN_MKT_BEFORE_FEES_AND_TAX_PAST_YEAR": "0.1305",
                "INVESTMENT_MIX": "{\"New Zealand fixed interest\":7.380000000000001,\"Unlisted property\":3.39,\"Cash and cash equivalents\":3.1499999999999972,\"Listed property\":0.0,\"Unknown\":0.0,\"Commodities\":0.0,\"Australasian equities\":18.13,\"International fixed interest\":9.959999999999999,\"International equities\":57.989999999999995,\"Other\":0.0}",
                "TARGET_MIX_CASH_AND_CASH_EQUIVALENTS": "2.0",
                "TARGET_MIX_NEW_ZEALAND_FIXED_INTEREST": "7.5",
                "TARGET_MIX_INTERNATIONAL_FIXED_INTEREST": "10.5",
                "TARGET_MIX_AUSTRALASION_EQUITIES": "",
                "TARGET_MIX_INTERNATIONAL_EQUITIES": "58.099999999999994",
                "TARGET_MIX_LISTED_PROPERTY": "0.0",
                "TARGET_MIX_UNLISTED_PROPERTY": "4.5",
                "TARGET_MIX_COMMODITIES": "0.0"
            } : {
                "TICKERLIST": "ERD.NZ",
                "F121": "Overweight",
                "F103": "EROAD",
                "F104": "ERD.NZ",
                "F105": "ERD NZ",
                "F107": "ERD",
                "F106": "NZC",
                "F100": "Australasia",
                "F101": "New Zealand",
                "F102": "Technology",
                "REFERENCE_YEAR": "2021",
                "F1001[-2]": "",
                "F1001[-1]": "",
                "F1001[0]": "",
                "F1001[1]": "",
                "F1001[2]": "",
                "F1000[-2]": "61.351896",
                "F1000[-1]": "81.2",
                "F1000[0]": "92.6922171337933",
                "F1000[1]": "106.08940448482154",
                "F1000[2]": "122.19637839057877",
                "F7200[-2]": "",
                "F7200[-1]": "",
                "F7200[0]": "",
                "F7200[1]": "",
                "F7200[2]": "",
                "F108": "NZD",
                "F1201[-2]": "15.633179000000005",
                "F1201[-1]": "27.1",
                "F1201[0]": "32.85305892982194",
                "F1201[1]": "38.83676509050355",
                "F1201[2]": "45.3213663852523",
                "F7220[-2]": "0.2548116687379964",
                "F7220[-1]": "0.33374384236453203",
                "F7220[0]": "0.3544316874242133",
                "F7220[1]": "0.36607581387696464",
                "F7220[2]": "0.3708896039487414",
                "F1250[-2]": "",
                "F1250[-1]": "",
                "F1250[0]": "",
                "F1250[1]": "",
                "F1250[2]": "",
                "F7230[-2]": "-0.037803558670786566",
                "F7230[-1]": "0.055418719211822655",
                "F7230[0]": "0.09184221926134388",
                "F7230[1]": "0.11150441964793545",
                "F7230[2]": "0.12580314842678353",
                "F1700[-2]": "-4.924458999999996",
                "F1700[-1]": "1.0000000000000004",
                "F1700[0]": "4.457462958254274",
                "F1700[1]": "6.728604918884742",
                "F1700[2]": "9.310164864368634",
                "F7000[-2]": "-7.31",
                "F7000[-1]": "1.55",
                "F7000[0]": "6.528329124393557",
                "F7000[1]": "9.854607401089085",
                "F7000[2]": "13.635518905302856",
                "F7000[-3]": "-5.61",
                "F7410[-2]": "",
                "F7410[-1]": "",
                "F7410[0]": "",
                "F7410[1]": "",
                "F7410[2]": "",
                "F7130[-2]": "",
                "F7130[-1]": "",
                "F7130[0]": "",
                "F7130[1]": "",
                "F7130[2]": "",
                "F4110[-2]": "67.283918",
                "F130": "3.61",
                "F135": "1",
                "F5340[-2]": "18.499889999999997",
                "F1040[-2]": "61.351896",
                "F4110[-1]": "67.361474",
                "F5340[-1]": "32.400000000000006",
                "F1040[-1]": "81.2",
                "F4110[0]": "68.278772",
                "F5340[0]": "41.404605148959426",
                "F1040[0]": "92.6922171337933",
                "F4110[1]": "68.278772",
                "F5340[1]": "46.38277188060295",
                "F1040[1]": "106.08940448482154",
                "F4110[2]": "68.278772",
                "F5340[2]": "48.89341188099929",
                "F1040[2]": "122.19637839057877",
                "F7015[-2]": "-7.31",
                "F7015[-1]": "1.55",
                "F7015[0]": "6.528329124393557",
                "F7015[1]": "9.854607401089085",
                "F7015[2]": "13.635518905302856",
                "F7060[-2]": "0",
                "F7060[-1]": "0",
                "F7060[0]": "0",
                "F7060[1]": "0",
                "F7060[2]": "0",
                "F124": "Initiation of coverage",
                "F120": "4.596619958451254",
                "EXT.HISTORICNOTES.LASTTARGETPRICE": "",
                "EXT.COMPARISON.F1040": "N/A",
                "EXT.COMPARISON.F7230": "N/A",
                "EXT.COMPARISON.F1905": "N/A",
                "F1905[0]": "4.457462958254274",
                "F1905[1]": "6.728604918884742",
                "PAGE_NUM": "",
                "F122": "2020-08-04",
                "F110": "NZD",
                "F1001[-3]": "",
                "F7220[-3]": "0.24093886794135855",
                "F7230[-3]": "-0.093310211537154",
                "F1040[-3]": "43.766496",
                "F1040[-4]": "32.763801",
                "F7250[-3]": "-0.06089515717572527",
                "F7250[-2]": "-0.09601057671223155",
                "F7250[-1]": "0.019493177387914236",
                "F7250[0]": "0.07994379087139572",
                "F7250[1]": "0.10768168245303286",
                "F7250[2]": "0.12967483820339973",
                "F109": "31 Mar",
                "F7015[-3]": "-5.61",
                "F7060[-3]": "0",
                "F3600[-3]": "56.766813",
                "F3600[-2]": "51.29079700000001",
                "F3600[-1]": "51.30000000000001",
                "F3600[0]": "55.75746295825428",
                "F3600[1]": "62.48606787713901",
                "F3600[2]": "71.79623274150767",
                "F4110[-3]": "61.668093",
                "F2700[-3]": "-18.591296",
                "F2700[-2]": "-13.079387",
                "F2700[-1]": "-12.799999999999997",
                "F2700[0]": "-9.00460514895942",
                "F2700[1]": "-4.978166731643526",
                "F2700[2]": "-2.510640000396336",
                "F5340[-3]": "4.612943999999999",
                "F1201[-3]": "10.545049999999996",
                "F1250[-3]": "",
                "F1251[-3]": "-4.083861000000004",
                "F1251[-2]": "-2.319319999999996",
                "F1251[-1]": "4.5",
                "F1251[0]": "8.513058929821941",
                "F1251[1]": "11.829437477875107",
                "F1251[2]": "15.372689127885383",
                "F1300[-3]": "-5.883056000000004",
                "F1300[-2]": "-5.115657999999996",
                "F1300[-1]": "1.4000000000000004",
                "F1300[0]": "6.190920775353158",
                "F1300[1]": "9.345284609562142",
                "F1300[2]": "12.930784533845326",
                "F1700[-3]": "-3.456824000000004",
                "F2000[-3]": "5.205344",
                "F2000[-2]": "14.2",
                "F2000[-1]": "23.1",
                "F2000[0]": "26.21843736188204",
                "F2000[1]": "33.00184007392259",
                "F2000[2]": "38.37626820909132",
                "F5100[-3]": "11.298668",
                "F5100[-2]": "16.2",
                "F5100[-1]": "11.6",
                "F5100[0]": "12.976910398731064",
                "F5100[1]": "14.215980200966088",
                "F5100[2]": "14.663565406869452",
                "F2200[-3]": "20.828054",
                "F2200[-2]": "0",
                "F2200[-1]": "0",
                "F2200[0]": "0",
                "F2200[1]": "0",
                "F2200[2]": "0",
                "F2230[-3]": "0",
                "F2230[-2]": "0",
                "F2230[-1]": "0",
                "F2230[0]": "0",
                "F2230[1]": "0",
                "F2230[2]": "0",
                "F5160[-3]": "19.454055",
                "F5160[-2]": "8.155336999999996",
                "F5160[-1]": "1.1613040000000083",
                "F5160[0]": "7.604605148959422",
                "F5160[1]": "4.978166731643526",
                "F5160[2]": "2.510640000396336",
                "F2150[-3]": "-5.647998",
                "F2150[-2]": "-6.669437000000003",
                "F2150[-1]": "-7.800000000000001",
                "F2150[0]": "-8.342299542041397",
                "F2150[1]": "-8.91150997672501",
                "F2150[2]": "-10.032322665866516",
                "F2240[-3]": "-0.754884",
                "F2240[-2]": "-0.9210289999999995",
                "F2240[-1]": "-1.1",
                "F2240[0]": "0",
                "F2240[1]": "0",
                "F2240[2]": "0",
                "F2130[-3]": "0",
                "F2130[-2]": "0",
                "F2130[-1]": "0",
                "F2130[0]": "0",
                "F2130[1]": "0",
                "F2130[2]": "0",
                "F2140[-3]": "0",
                "F2140[-2]": "0",
                "F2140[-1]": "0",
                "F2140[0]": "0",
                "F2140[1]": "0",
                "F2140[2]": "0",
                "F2300[-3]": "20.935056000000003",
                "F2300[-2]": "-5.731609000000002",
                "F2300[-1]": "-12.738805999999999",
                "F2300[0]": "-1.4",
                "F2300[1]": "0",
                "F2300[2]": "0",
                "F2060[-3]": "5.59869",
                "F2060[-2]": "-2.376602000000001",
                "F2060[-1]": "-1.8906929999999988",
                "F2060[0]": "2.5790255963722366",
                "F2060[1]": "0.734092457590588",
                "F2060[2]": "0.8825739126442329",
                "F3010[-3]": "21.870415",
                "F3010[-2]": "16.138806",
                "F3010[-1]": "3.4",
                "F3010[0]": "2",
                "F3010[1]": "2",
                "F3010[2]": "2",
                "F3130[-3]": "23.848227",
                "F3130[-2]": "33.901289",
                "F3130[-1]": "37.4",
                "F3130[0]": "41.400910398731064",
                "F3130[1]": "45.680672104001694",
                "F3130[2]": "49.38087620591074",
                "F5220[-3]": "0",
                "F5220[-2]": "0",
                "F5220[-1]": "0",
                "F5220[0]": "0",
                "F5220[1]": "0",
                "F5220[2]": "0",
                "F3200[-3]": "111.148191",
                "F3200[-2]": "123.199577",
                "F3200[-1]": "125.80000000000001",
                "F3200[0]": "138.5503629008651",
                "F3200[1]": "151.5417963521769",
                "F3200[2]": "164.9071055640693",
                "F3320[-3]": "0",
                "F3320[-2]": "0",
                "F3320[-1]": "0",
                "F3320[0]": "0",
                "F3320[1]": "0",
                "F3320[2]": "0",
                "F3420[-3]": "26.483359",
                "F3420[-2]": "34.638695999999996",
                "F3420[-1]": "35.800000000000004",
                "F3420[0]": "43.404605148959426",
                "F3420[1]": "48.38277188060295",
                "F3420[2]": "50.89341188099929",
                "F3500[-3]": "54.381378",
                "F3500[-2]": "71.90878",
                "F3500[-1]": "74.5",
                "F3500[0]": "82.79289994261083",
                "F3500[1]": "89.0557284750379",
                "F3500[2]": "93.11087282256163",
                "F3700[-3]": "56.76681299999999",
                "F3700[-2]": "51.290797000000005",
                "F3700[-1]": "51.300000000000004",
                "F3700[0]": "55.75746295825428",
                "F3700[1]": "62.48606787713903",
                "F3700[2]": "71.79623274150765",
                "F7015[-4]": "-8.82",
                "F7090[-3]": "0",
                "F7090[-2]": "0",
                "F7090[-1]": "0",
                "F7090[0]": "0",
                "F7090[1]": "0",
                "F7090[2]": "0",
                "F7400[-3]": "0.08126128200996592",
                "F7400[-2]": "0.36068634301003344",
                "F7400[-1]": "0.6315789473684211",
                "F7400[0]": "0.742584094616341",
                "F7400[1]": "0.7422898168564775",
                "F7400[2]": "0.6810024706593342",
                "F7460[-3]": "5.598836382016695",
                "F7460[-2]": "5.59058990722867",
                "F7460[-1]": "9.678571428571429",
                "F7460[0]": "14.14776242602046",
                "F7460[1]": "15.633806431919917",
                "F7460[2]": "18.55984320430369",
                "EXT.PERTICKER.DISCLOSURE": "None",
                "F160": "None",
                "EXT.HISTORICNOTES.DATA": ""
            }
        },
        "configuration": JSON.stringify(categoryChartConfig),
        "className": "CategoryChart",
        "dataSuffix": null
    }
    return dataObj
}

export function getCategoryChartConfig(colourList, companyId) {
    const allColoursList = colourList || {
        axis: companyId === 2 ? '#D6D5D2': '#2B2E34',
        chartBackground: '#ffffff', // companyId === 2 ? '#EDECE8' : '#ffffff
        grid: companyId === 2 ? '#D6D5D2' : '#ffffff',
        line1: '#d81521',
        line2: '#008fd2',
    }
    const dataObj = {
        title: { value: '' },        
        width: companyId === 2 ? 454 : 240,
        height: companyId === 2 ? 287 : 132,
        title: { value: '' },
        styles: { p: companyId === 2 ? 'chart-text' : 'body-text-small', h1: companyId === 2 ? 'section-header-small' : 'body-header' }, // Warning: category chart still depends on a template to be generated
        // styles: { p: categoryChartText.p, h1: categoryChartText.h1 },
        backgroundColour: allColoursList.chartBackground,
        gridColour: allColoursList.grid,
        gridHorizontal: companyId === 2 ? true : null,
        gridVertical: companyId === 2 ? false : null,
        borderBackgroundColour: companyId === 2 ? '#EDECE8' : '#ffffff',
        gridStrokeWidth: 0.25,
        axisColour: allColoursList.axis,
        axisStrokeWidth: 0.5,
        rightAxisZeroBased: true,
        renderValuesAboveBars: companyId === 2 ? true : false,
        categories: companyId === 2 ? [
            // { value: "${format:1:0:CURRENT_YEAR-6}" },
            // { value: "${format:1:0:CURRENT_YEAR-5}" },
            // { value: "${format:1:0:CURRENT_YEAR-4}" },
            // { value: "${format:1:0:CURRENT_YEAR-3}" },
            // { value: "${format:1:0:CURRENT_YEAR-2}" },
            // { value: "${format:1:0:CURRENT_YEAR-1}" },
            // { value: "${CURRENT_YEAR}" },
            // { value: "SI" }
            { value: "Year to \n31 March " + "${format:1:0:CURRENT_YEAR-6}" },
            { value: "Year to \n31 March ${format:1:0:CURRENT_YEAR-5}" },
            { value: "Year to \n31 March ${format:1:0:CURRENT_YEAR-4}" },
            { value: "Year to \n31 March ${format:1:0:CURRENT_YEAR-3}" },
            { value: "Year to \n31 March ${format:1:0:CURRENT_YEAR-2}" },
            { value: "Year to \n31 March ${format:1:0:CURRENT_YEAR-1}" },
            { value: "Year to \n31 March ${CURRENT_YEAR}" },
            { value: "Since Inception" }

        ] : [
            { value: "${format:1:0:REFERENCE_YEAR-3}${F1001[-3]}" },
            { value: "${format:1:0:REFERENCE_YEAR-2}${F1001[-2]}" },
            { value: "${format:1:0:REFERENCE_YEAR-1}${F1001[-1]}" },
            { value: "${format:1:0:REFERENCE_YEAR}${F1001[0]}" },
            { value: "${format:1:0:REFERENCE_YEAR+1}${F1001[1]}" },
            { value: "${format:1:0:REFERENCE_YEAR+2}${F1001[2]}" }
        ],
        series: [
            {
                name: { value: "Lorem" },
                type: companyId === 2 ? 'bar' : "line",
                lineWidth: 1,
                colour: allColoursList.line2,
                axis: "left",
                data: companyId === 2 ?
                    [{ value: "${RETURN_FUND[-6]*100}" }, { value: "${RETURN_FUND[-5]*100}" }, { value: "${RETURN_FUND[-4]*100}" }, { value: "${RETURN_FUND[-3]*100}" }, { value: "${RETURN_FUND[-2]*100}" }, { value: "${RETURN_FUND[-1]*100}" }, { value: "${RETURN_FUND[0]*100}" }, { value: "${RETURN_FUND[1]*100}" }] :
                    [{ value: "${F7220[-3]*100}" }, { value: "${F7220[-2]*100}" }, { value: "${F7220[-1]*100}" }, { value: "${F7220[0]*100}" }, { value: "${F7220[1]*100}" }, { value: "${F7220[2]*100}" }]
            },
            {
                name: { value: "Lorem" },
                type: companyId === 2 ? 'bar' : "line",
                lineWidth: 1,
                colour: allColoursList.line1,
                axis: "left",
                data: companyId === 2 ?
                    [{ value: "${RETURN_BENCHMARK[-6]*100}" }, { value: "${RETURN_BENCHMARK[-5]*100}" }, { value: "${RETURN_BENCHMARK[-4]*100}" }, { value: "${RETURN_BENCHMARK[-3]*100}" }, { value: "${RETURN_BENCHMARK[-2]*100}" }, { value: "${RETURN_BENCHMARK[-1]*100}" }, { value: "${RETURN_BENCHMARK[0]*100}" }, { value: "${RETURN_BENCHMARK[1]*100}" }] :
                    [{ value: "${F7230[-3]*100}" }, { value: "${F7230[-2]*100}" }, { value: "${F7230[-1]*100}" }, { value: "${F7230[0]*100}" }, { value: "${F7230[1]*100}" }, { value: "${F7230[2]*100}" }]
            }
        ]
    }
    return dataObj
}

export const pieChartDataObject = (companyId) => {
    const dataObj = companyId === 2 ? {
        "TICKERLIST": "FND697",
        "FUND_NUMBER": "",
        "PUBLISH_DATE": "",
        "QUARTER_ENDING_DATE": "2024-06-30",
        "FUND_NAME": "Growth Fund",
        "FUND_VALUE": "2.30118791293E9",
        "FUND_STARTED_DATE": "2016-09-01",
        "FUND_DESCRIPTION": "The Growth Fund provides investors with a limited exposure to income assets, but most of its investments are in growth assets.  ",
        "RISK": "5",
        "CURRENT_YEAR": "2024",
        "RETURN_FUND[-6]": "0.0819",
        "RETURN_FUND[-5]": "0.0781",
        "RETURN_FUND[-4]": "-0.0259",
        "RETURN_FUND[-3]": "0.2741",
        "RETURN_FUND[-2]": "0.0322",
        "RETURN_FUND[-1]": "-0.0405",
        "RETURN_FUND[0]": "0.1604",
        "RETURN_FUND[1]": "0.0768",
        "RETURN_BENCHMARK[-6]": "0.0997",
        "RETURN_BENCHMARK[-5]": "0.096",
        "RETURN_BENCHMARK[-4]": "-0.0101",
        "RETURN_BENCHMARK[-3]": "0.296",
        "RETURN_BENCHMARK[-2]": "0.0391",
        "RETURN_BENCHMARK[-1]": "-0.0363",
        "RETURN_BENCHMARK[0]": "0.1748",
        "RETURN_BENCHMARK[1]": "0.0908",
        "ANNUAL_RTN_AFTER_FEES_AND_TAX_AVG_5_YEAR": "0.1197",
        "ANNUAL_RTN_AFTER_FEES_AND_TAX_PAST_YEAR": "0.0682",
        "ANNUAL_RTN_AFTER_FEES_BEFORE_TAX_AVG_5_YEAR": "0.0786",
        "ANNUAL_RTN_AFTER_FEES_BEFORE_TAX_PAST_YEAR": "0.1329",
        "ANNUAL_RTN_MKT_BEFORE_FEES_AND_TAX_AVG_5_YEAR": "0.079",
        "ANNUAL_RTN_MKT_BEFORE_FEES_AND_TAX_PAST_YEAR": "0.1305",
        "INVESTMENT_MIX": "{\"New Zealand fixed interest\":7.380000000000001,\"Unlisted property\":3.39,\"Cash and cash equivalents\":3.1499999999999972,\"Listed property\":0.0,\"Unknown\":0.0,\"Commodities\":0.0,\"Australasian equities\":18.13,\"International fixed interest\":9.959999999999999,\"International equities\":57.989999999999995,\"Other\":0.0}",
        "TARGET_MIX_CASH_AND_CASH_EQUIVALENTS": "2.0",
        "TARGET_MIX_NEW_ZEALAND_FIXED_INTEREST": "7.5",
        "TARGET_MIX_INTERNATIONAL_FIXED_INTEREST": "10.5",
        "TARGET_MIX_AUSTRALASION_EQUITIES": "",
        "TARGET_MIX_INTERNATIONAL_EQUITIES": "58.099999999999994",
        "TARGET_MIX_LISTED_PROPERTY": "0.0",
        "TARGET_MIX_UNLISTED_PROPERTY": "4.5",
        "TARGET_MIX_COMMODITIES": "0.0"
    } : {
        "TICKERGROUP": "Balanced Fund",
        "TICKER": "Balanced",
        "HISTORICPRICESREFERENCEDATE": "2023-10-01",
        "HISTORICVALUATIONS": "[15123.0,14123.0,13000.0,13111.0,12123.0,12111.0,12007.0,12009.0,11888.0,11777.0,11666.0,11555.0,11565.0,11500.0,11400.0,11300.0,11450.0,11400.0,11350.0,11250.0,11200.0,11280.0,11100.0,11000.0,11008.0,10998.0,10990.0,10890.0,10790.0,11001.0,10990.0,10800.0,10700.0,10550.0,10720.0,10500.0,10400.0,10300.0,10305.0,10350.0,10300.0,10250.0,10000.0]",
        "WHEREGRAPH": "{\"Australian Fixed Interest\":34.3,\"International Fixed Interest\":18.6,\"Cash\":15.1,\"Australian Equities\":12.7,\"International Equities\":14.4,\"Property\":1.9,\"Other\":3.1}",
        "WHATGRAPH": "{\"Growth\":60.0,\"Income\":40.0}",
        "TICKERLIST": "Balanced Fund"
    }
    return dataObj
}
