import React, { useEffect, useState } from 'react';
import { ChevronsLeft, Info, Save } from 'react-feather';
import { withData } from '../../../DataController';
import { useRemote, useStorage } from '../../../Utils/Utils';
import SidebarExtensionWrapper from './SideBarExtensionWrapper';
import SideBarSection from './SideBarSection';
import DisclosureList from '../../../layouts/AdminPage/SchemesAndFunds/DisclosureList';
import Modal from '../../Modals/Modal';
import FlexWrapper from '../../../layouts/FlexWrapper';
import PageContentToggle from '../../Menus/PageContentToggle';
import './OptionsSidebarExtension.scss';


const NotesSidebarExtension = ({ slideIn, slideOut, template, data, pinSelected, setPinSelected, setShowPinModal, elementsWithSupercript, locatedFootnote, setLocatedFootnote, replaceFootNoteWarning, setReplaceFootNoteWarning }) => {
    const [taggedElements, setTaggedElements] = useState([]);
    const [notes, setNotes] = useState([]);
    const [selectedNotes, setSelectedNotes] = useState([]);
    const [extensionSize, setExtensionSize] = useState('s')
    const [superscriptToReplace, setSuperscriptToReplace] = useState(null)
    const [newSuperscript, setNewSuperscript] = useState(null)
    const [selectedTab, setSelectedTab] = useState('active')
    const [newSelectedNotes, setNewSelectedNotes] = useState([])
    const [fund, setFund] = useState(null)
    const [unpinnedNotes, setUnpinnedNotes] = useState(null)

    const remote = useRemote()
    const storage = useStorage();

    useEffect(() => {
        if (!template) return;

        const { ticker } = template
        if (ticker.subSelectionName && ticker.subSelectionName === 'Fund' && data && data.tickers && data.tickers[0]) {
            remote.get(`/funds/funds/${data.tickers[0]}`)
                .then((res) => {
                    if (!res || res.length === 0) return
                    setNotes(res.disclosures)
                    setFund(res)
                })
        }
        return () => { setPinSelected(null); endElementSelection() }
    }, [template, data.tickers])

    useEffect(() => {
        if (data && data.localContentData && data.localContentData.FOOTNOTES) {
            const parsedNotes = JSON.parse(data.localContentData.FOOTNOTES)
            setTaggedElements(parsedNotes)
            setSelectedNotes(parsedNotes.map(note => note.offerNoteId))
        }
    }, [data.localContentData.FOOTNOTES])

    useEffect(() => {
        if (notes) {
            const foundUnpinnedNotes = notes && notes.length > 0 && notes.filter(n => n.selected && !selectedNotes.includes(n.id))
            setUnpinnedNotes(foundUnpinnedNotes)
        }
    }, [notes, selectedNotes])

    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.key === 'Escape' && pinSelected) {
                setShowPinModal(false)
                setPinSelected(null)
            }
        }
        window.addEventListener('keydown', onKeyDown)
        return () => window.removeEventListener('keydown', onKeyDown)
    }, [pinSelected])

    const isFootnoteTarget = (e) => {
        return e?.target?.className.includes('footnoteTarget')
    }

    const onTagElementClick = (e, pinId, pinned) => {
        if (e?.target?.children[0]?.tagName === 'SUP' && e?.target?.children[0]?.innerText && pinId) {
            setReplaceFootNoteWarning(true)
            setSuperscriptToReplace(+e.target.children[0].innerText)
            setNewSuperscript(pinId)

        } else if (e?.target?.className.includes('footnoteTarget') || !e) {
            let taggedElementsList;

            if (pinned) {
                taggedElementsList = taggedElements.filter(n => n.offerNoteId !== pinId)

            } else if (isFootnoteTarget(e)) {
                const foundNote = notes.find(note => note.id === pinId)
                const newPinnedNote = {
                    footnoteId: e.target.id,
                    offerNoteId: foundNote.id,
                    noteText: foundNote.disclosure
                }
                taggedElementsList = [...taggedElements, newPinnedNote]
            }

            const footnoteIdsArray = (taggedElementsList?.map(note => note.footnoteId))
            const filterdList = elementsWithSupercript.filter(element => footnoteIdsArray && footnoteIdsArray.length > 0 && footnoteIdsArray.includes(element.configuration.footnoteId))

            const footnoteIdToIndex = filterdList?.reduce((acc, item, index) => {
                acc[item.configuration.footnoteId] = index + 1;
                return acc;
            }, {});

            const sortedNotes = taggedElementsList
                .map(note => ({ ...note, superscript: footnoteIdToIndex[note.footnoteId] }))
                .sort((a, b) => a.superscript - b.superscript);

            const payload = JSON.stringify(sortedNotes)

            data.updateLocalData('FOOTNOTES', payload, 'SIDEBAR', 'FOOTNOTES')
            setTaggedElements(taggedElementsList);
            setSelectedNotes(taggedElementsList.map(note => note.offerNoteId))
            setPinSelected(null, false)
            endElementSelection()
        }
    }

    function cancelReplace() {
        setPinSelected(null, false)
        setReplaceFootNoteWarning(false)
        endElementSelection()
    }

    function handleTag(pinId, boolean) {
        let page = document.getElementById('builder-page-page-wrapper');
        const eventListener = (e) => onTagElementClick(e, pinId, boolean);
        page.addEventListener('click', eventListener)
        page._tagEventListener = eventListener
    };

    function endElementSelection() {
        let page = document.getElementById('builder-page-page-wrapper');
        if (page?._tagEventListener) {
            page.removeEventListener('click', page._tagEventListener);
            delete page._tagEventListener; // Clean up the reference
        }
    };

    function replaceFootNote() {
        const foundNote = notes.find(note => note.id === newSuperscript)
        const updatedFootnotes = taggedElements.map(n => {
            if (n.superscript === superscriptToReplace) {
                return { ...n, offerNoteId: newSuperscript, noteText: foundNote.disclosure }
            } else return n
        })

        const sortedNotes = updatedFootnotes.sort((a, b) => a.superscript - b.superscript);
        const payload = JSON.stringify(sortedNotes)

        data.updateLocalData('FOOTNOTES', payload, 'SIDEBAR', 'FOOTNOTES')
        setTaggedElements(updatedFootnotes);
        setSelectedNotes(updatedFootnotes.map(note => note.offerNoteId))
        setPinSelected(null, false)
        setReplaceFootNoteWarning(false)
        endElementSelection()
    }

    function expandSideBarRight() {
        if (extensionSize === 's') {
            storage.put('research-master-grid', 'right-m')
            setExtensionSize('m')
        } else if (extensionSize === 'm') {
            storage.put('research-master-grid', 'right-l')
            setExtensionSize('l')
        } else if (extensionSize === 'l') {
            storage.put('research-master-grid', 'sideBarExtRightActive')
            setExtensionSize('s')
        }
    }

    function onPin(pinId, pinned) {
        if (pinned) setPinSelected(null, false, () => onTagElementClick(null, pinId, pinned))
        else if (pinSelected === pinId) {
            setPinSelected(null, false)
        }
        else setPinSelected(pinId, true, () => handleTag(pinId, false))
    }

    function updateNotes() {
        const updateList = notes.map(n => {
            if (newSelectedNotes.length > 0 && newSelectedNotes.includes(n.id)) {
                return { ...n, selected: true }
            } else return n
        })
        setNotes(updateList)
        const payload = { ...fund, disclosures: updateList }
        remote.post(`/funds/funds/${data.tickers[0]}`, payload).then(updatedFund => {
            setFund(updatedFund);
        }).then(() => {
            if (newSelectedNotes.length === 1) {
                // toastSuccess('Note activated')
                setNewSelectedNotes([])
            } else if (newSelectedNotes.length > 1) {
                // toastSuccess('Notes activated')
                setNewSelectedNotes([])
            }
        })
    }

    return (
        <SidebarExtensionWrapper position='right' shadow slideIn={slideIn} slideOut={slideOut} rightExtSize={extensionSize} >
            <SideBarSection
                header={"Note Tags"}
                icon={unpinnedNotes && <Info size={'1rem'} color='#f8dc64' />}
                tooltipMessage={unpinnedNotes && unpinnedNotes.length > 0 && " Unpinned Notes: " + unpinnedNotes.length}
                filters={
                    <FlexWrapper align="center" gap="m">
                        <ChevronsLeft className='expand-sidebar-icon' onClick={expandSideBarRight} />
                    </FlexWrapper>
                }
            >
                <FlexWrapper flexDirection='row' justify='space-between' className='pb-l'>
                    <PageContentToggle labels={[
                        { label: 'Active', active: selectedTab === 'active', onClick: () => setSelectedTab('active') },
                        { label: 'Inactive', active: selectedTab === 'inactive', onClick: () => setSelectedTab('inactive') },
                    ]} />
                    {selectedTab === 'inactive' && <Save size={16} color={newSelectedNotes.length > 0 ? '#0E8EFD' : '#ADB1BD'} className={newSelectedNotes.length > 0 ? 'cursor-pointer' : ''} onClick={() => newSelectedNotes.length > 0 ? updateNotes() : null} />}
                </FlexWrapper>
                <DisclosureList
                    data={notes}
                    activeNotes={selectedTab === 'active' ? true : false}
                    locatedFootnote={locatedFootnote}
                    setLocatedFootnote={setLocatedFootnote}
                    onPin={onPin}
                    pinnedElements={taggedElements}
                    pinSelected={pinSelected}
                    selectedNotes={selectedNotes}
                    newSelectedNotes={newSelectedNotes}
                    setNewSelectedNotes={setNewSelectedNotes}
                />
            </SideBarSection>
            {replaceFootNoteWarning &&
                <Modal
                    header='Replace Tag'
                    cancelButton={{ onClick: () => cancelReplace() }}
                    confirmButton={{ onClick: () => replaceFootNote() }}
                >
                    <p>Are you sure you want to replace this tag?</p>
                </Modal>
            }
        </SidebarExtensionWrapper >
    );
}

export default withData(NotesSidebarExtension);

// Bellow an example of weird blank space character that was making the Simplicity QFU pdf fail. Undo the commented out text to see the weird blank space
// The NZ Cash Fund provides investors with exposure to debt securities.
