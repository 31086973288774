import React, { useState } from 'react';
import { useRemote } from '../../../Utils/Utils';
import MiniUsersList from '../../Shared/TablesAndLists/MiniUsersList';
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';

const DistributionMembersList = ({ members, editMode, list, setMembers, createMode, organisations }) => {

	const [userToRemove, setUserToRemove] = useState(null)
	const [displayModal, setDisplayModal] = useState([])
	const remote = useRemote();

	const addUser = (user) => {
		remote.put(`/crm/lists/${list.id}/members/${user.value}`)
			.then((response) => {
				toastSuccess('Member Added')
				let userToAdd = user.userDTO
				if (organisations && organisations.length > 0) {
					let userOrg = organisations.find((org) => org.id === userToAdd.organisationId)
					if (userOrg && userOrg.name) userToAdd.organisation = userOrg.name
					else userToAdd.organisation = ''
				} else userToAdd.organisation = ''
				setMembers([...members, userToAdd])
			})
			.catch(() => toastDanger('Member could not be added'))
	}

	const removeUser = (user) => {
		remote.delete(`/crm/lists/${list.id}/members/${user.id}`)
			.then((response) => {
				setDisplayModal([false, toastSuccess("Member removed")])
				let newArray = []
				members.forEach((member) => {

					if (member.id !== user.id) {
						newArray.push(member)
					}
				})
				setMembers(newArray)
			})
			.catch(() => toastDanger('Member could not be removed'))
	}

	return <MiniUsersList
		list={list}
		data={members}
		editMode={editMode}
		setData={setMembers}
		addUser={addUser}
		removeUser={removeUser}
		setUserToRemove={setUserToRemove}
		displayModal={displayModal}
		createMode={createMode}
		members
		allowExport
	/>;
}

export default DistributionMembersList;