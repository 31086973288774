import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp, Upload, Search } from 'react-feather';
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp'
import withRouteChange from "@threeskye/route-change"
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Button from '../../../components/Buttons/Button';
import SimpleSearchFilter, { filterBy } from '../../../components/Input/SimpleSearchFilter';
import AddAssetClass from './AddAssetClass'
import LoadingIcon from '../../Shared/LoadingIcon';
import "../ModelPortfolio/ModelPortfolioPage.scss"


const AssetClassesList = ({ changeRoute }) => {
    const remote = useRemote();

    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [sortingColumn, setSortingColumn] = useState({ column: "Name", ascending: true })
    const [addMode, setAddMode] = useState(false)

    useEffect(() => {
        remote.get("assets/classes/list").then(setList).then(() => setLoading(false))
    }, [remote])

    function toggleSort(column) {
        if (column === null) {
            setSortingColumn({ column: "Name", ascending: true })
            return
        }
        const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
        setSortingColumn({ column: column, ascending: ascending })
    }

    function sortTable(dataToSort) {
        if (sortingColumn.column === null) {
            return dataToSort
        }

        let tableData = dataToSort
        const ascending = sortingColumn.ascending

        switch (sortingColumn.column) {
            case "Name":
                tableData.sort((a, b) => ascending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
                break;
            case "Total Assets":
                tableData.sort((a, b) => {
                    return ascending ? a.numAssets - b.numAssets : b.numAssets - a.numAssets
                })
                break;
            default:
                break;
        }
        return tableData
    }

    function filter(dataToFilter) {
        if (searchTerm === null || searchTerm.trim().size === 0) {
            return dataToFilter
        }
        return dataToFilter.filter((datum) => filterBy(datum, "name", searchTerm))
    }

    const filteredData = filter(list)
    const sortedData = sortTable(filteredData)

    const colWidths = ["auto", "25%"]
    const headers = [
        {
            text: (
                <div className='sorting-header' onClick={() => toggleSort("Name")} >
                    Name
                    {sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => toggleSort("Total Assets")} >
                    Total Assets
                    {sortingColumn && sortingColumn.column === "Total Assets" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Total Assets" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        }
    ]

    const dataMap = (dataToMap) => {
        return dataToMap && dataToMap.length > 0 && dataToMap.map((assetClass) => {
            const { name, id, numAssets } = assetClass
            return (
                <tr className='tr-borders cursor-pointer' key={name + '-' + id} onClick={() => changeRoute(`${ROUTE_PATHS[PAGES.ADMIN]}/asset-class/${id}`)}>
                    <td className="model-portfolio-class-name" style={{ border: "0" }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ paddingLeft: 2 }}>{name}</p>
                        </div>
                    </td>
                    <td>{numAssets}</td>
                </tr>
            )
        })
    }

    if (loading) return (
        <Page>
            <PageContentBodyGrid rowGap="xl" gridTemplateRows="1fr" splitScroll>
                <LoadingIcon />
            </PageContentBodyGrid>
        </Page>
    )

    return (addMode ?
        <AddAssetClass
            classesList={list}
            setAddMode={setAddMode}
        /> :
        <Page>
            <PageContentBanner divider gap='m'>
                <SimpleSearchFilter
                    placeholder={`Search`}
                    setSearchTerm={setSearchTerm}
                    size="small"
                    width="l"
                    icon={<Search />}
                    searchTerm={searchTerm}
                />
                <Button onClick={() => setAddMode(true)}>{"Add New"}</Button>
                <Button icon={<Upload />} onClick={() => setAddMode(true)}>Import</Button>
            </PageContentBanner>
            <PageContentBodyGrid id="asset-class-management" rowGap="xl" gridTemplateRows="1fr" splitScroll>
                <PageGridItem container col="1 / span 12" style={{ marginBottom: "2rem" }}>
                    <Table
                        minWidth={450}
                        colWidths={colWidths}
                        headers={headers}
                        dataMap={dataMap(sortedData)}
                        dataLoading={loading}
                    />
                </PageGridItem>
            </PageContentBodyGrid >
        </Page >
    );
}

export default withRouteChange(AssetClassesList);