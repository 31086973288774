import React, { useEffect, useRef, useState } from 'react';
import { Download, Key, Save, Trash, User, Users } from 'react-feather';
import Table from '../../../components/Table/Table';
import PageGridItem from '../Page/PageGridItem';
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import TableActionIcon from '../../../components/Table/TableActionIcon';
import useWindowDimensions, { useRemote } from '../../../Utils/Utils';
import DetailSectionHeader from '../DetailSection/DetailSectionHeader';
import SearchField from '../../../components/Input/SearchField';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';
import NoResultsMessage from '../../../components/EmptyMessage/NoResultsMessage';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import FlexWrapper from '../../FlexWrapper';
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';
import CSVButton from '../../../components/Buttons/CSVButton';
import { useHistory } from 'react-router-dom';

const headers = [
	{ key: "id", label: "id" },
	{ key: "firstName", label: "firstName" },
	{ key: "middleNames", label: "middleNames" },
	{ key: "lastName", label: "lastName" },
	{ key: "preferredName", label: "preferredName" },
	{ key: "email", label: "email" },
	{ key: "companyId", label: "companyId" },
	{ key: "role", label: "role" },
	{ key: "location", label: "location" },
	{ key: "organisationId", label: "organisationId" },
	{ key: "organisation", label: "organisation" },
	{ key: "keyContact", label: "keyContact" },
	{ key: "deleted", label: "deleted" },
	{ key: "salutation", label: "salutation" },
	{ key: "phone", label: "phone" },
	{ key: "electronicDelivery", label: "electronicDelivery" },
	{ key: "emailSuppressed", label: "emailSuppressed" },
	{ key: "hasPortalAccess", label: "hasPortalAccess" },
	{ key: "sendEmailNotifications", label: "sendEmailNotifications" },
	{ key: "sendTextNotifications", label: "sendTextNotifications" },
	{ key: "sendPortalNotifications", label: "sendPortalNotifications" },
	{ key: "accessToModel", label: "accessToModel" }
]

const MiniUsersList = ({ editMode, data, members, setData, addUser, removeUser, displayModal, createMode, allowExport }) => {
	const colWidths = [80 + "%", 20 + "%"];
	const [addUsersIsLoading, setAddUsersIsLoading] = useState(true)
	const [addableUsers, setAddableUsers] = useState([])
	const { width } = useWindowDimensions();
	const remote = useRemote()
	// const [showModal, setShowModal] = useState(false);
	const [filteredData, setFilteredData] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	const searchSelect = useRef();
	const history = useHistory();

	useEffect(() => { data && getAddUsersOptions(); }, [data])
	// useEffect(() => { setShowModal(displayModal[0], displayModal[1]) }, [displayModal])

	const setOrRemoveKeyContact = (e, user, idx) => {
		e.stopPropagation()
		if (user.keyContact) {
			remote.put(`/crm/contacts/${user.id}`, { ...user, keyContact: false }).then((response) => {
				if (response.success) {
					let newData = [...data]
					newData[idx] = { ...user, keyContact: false }
					setData(newData)
					toastSuccess(`${user.firstName} ${user.lastName} has been removed as a key contact`)
				} else {
					toastDanger(`${user.firstName} ${user.lastName} could not be removed as a key contact`)
				}
			})
		} else {
			remote.put(`/crm/contacts/${user.id}`, { ...user, keyContact: true }).then((response) => {
				if (response.success) {
					let newData = [...data]
					newData[idx] = { ...user, keyContact: true }
					setData(newData)
					toastSuccess(`${user.firstName} ${user.lastName} has been added as a key contact`)
				} else {
					toastDanger(`${user.firstName} ${user.lastName} could not be added as a key contact`)
				}
			})
		}
	}

	const getAddUsersOptions = () => {
		// console.log("getting AddUsersOptions")
		remote.get("/crm/contacts").then((response) => {
			// console.log("getting AddUsersOptions1")
			let mappedusers = response
				.map((user) => { return { value: user.id, label: `${user.firstName} ${user.lastName}`, userDTO: user } })
				.filter((user) => {
					// console.log(user)
					return data.filter((userCurrentlyInList) => {
						// console.log(userCurrentlyInList, user, userCurrentlyInList.id == user.value)
						return userCurrentlyInList.id == user.value;
					}).length == 0
				});
			setAddUsersIsLoading(false)
			setAddableUsers(mappedusers)
		})
	}

	const dataMap = (dataToMap) => dataToMap && dataToMap.map((user, idx) => {
		return (
			<tr key={idx} className={editMode ? "" : "cursor-pointer"} onClick={editMode ? null : () => history.push(ROUTE_PATHS[PAGES.CRM] + "/users/user/" + user.id)}>
				<td>
					<div className="truncate">
						{user.firstName + " " + user.lastName} <span className="extension-label ml-s">{user.email}</span>{" "}
					</div>
				</td>
				<td className="table-action-icons transition-fade-in-slow">
					{!members && (
						<TableActionIcon
							colour={user.keyContact ? "primary" : ""}
							icon={<Key />}
							toolTip={user.keyContact ? "Remove as key contact" : "Set key contact"}
							onClick={(e) => setOrRemoveKeyContact(e, user, idx)}
						/>
					)}
					<TableActionIcon
						icon={<Trash />}
						hover="danger"
						toolTip={`Remove ${inputLabel = members ? "member" : "user"}`}
						onClick={(e) => {
							e.stopPropagation();
							removeUser(user);
						}}
					/>
				</td>
			</tr>
		);
	})

	let inputLabel = members ? "members" : "users";
	const noData = data && data.length <= 0;
	const noSearchResults = false;
	const xl = width > 2250;
	const l = width > 1900;

	return (
		<>
			<PageGridItem col="1 / span 6" justifyContent="between" gap="m" wrap>
				<FlexWrapper gap="l">
					<DetailSectionHeader icon={<Users />} header={(members ? "Members " : "Users ") + "(" + (data && data.length ? data.length : 0) + ")"} />
					{allowExport &&
						<CSVButton
							headers={headers}
							data={hasSearchTerm ? filteredData : data}
						>
							<Download style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }} /> Export CSV
						</CSVButton>
					}
				</FlexWrapper>
				<FlexWrapper gap="s">
					{!noData && (
						<SearchTermFilter
							size="small"
							width={xl ? "l" : l ? "m" : "s"}
							placeholder={`Search ${inputLabel}`}
							dataSet={data}
							setFilteredData={setFilteredData}
							path={["companyName", "firstName", "lastName", "preferredName", "email", "role"]}
							hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
						/>
					)}
					{members && createMode ? (
						""
					) : (
						<SearchField
							placeholder={`Add ${inputLabel}`}
							size="small"
							width={noData && xl ? "l" : l ? "m" : "s"}
							isLoading={addUsersIsLoading}
							options={addableUsers}
							onChange={(user) => { addUser(user); setAddableUsers([addableUsers.filter((addableUser) => addableUser.value !== user.value)]) }}
							value=""
							innerRef={searchSelect}
							openMenuOnFocus
						/>
					)}
				</FlexWrapper>
			</PageGridItem>
			<PageGridItem col="1 / span 6">
				{members && createMode ? (
					<SuggestionMessage
						message={
							<>
								Create list before adding members.
							</>
						}
					/>
				) : noData ? (
					<SuggestionMessage
						message={
							<>
								No {inputLabel} added yet. <span className="text-link" onClick={() => searchSelect && searchSelect.current.focus()}>Try adding one now.</span>
							</>
						}
					/>
				) : noSearchResults ? (
					<NoResultsMessage compact />
				) : (
					<Table colWidths={colWidths} dataMap={dataMap(hasSearchTerm ? filteredData : data)} />
				)}
			</PageGridItem>
		</>
	);
}

export default MiniUsersList;