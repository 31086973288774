import React, { useState, useEffect } from "react";
import { useRemote, getIcon, useStorage } from '../../Utils/Utils';
import { gicsSectors } from '../../Utils/DummyData';
import withCraigsDocs from "./withCraigsDocs";
import Table from "../../components/Table/Table"
import LibraryTableRow from "./LibraryTableRow";
import { useLocation } from "react-router-dom";
import { AlertCircle, Mail, Send } from "react-feather";
import "./CraigsLibraryTable.scss";


const CraigsLibraryTable = ({ craigsDocs, onCraigsDocClick, onDeliveryClick, filterOnclick }) => {
	const [data, setData] = useState([])
	const [expandedRows, setExpandedRows] = useState([])

	const [tickers, setTickers] = useState([])
	const [models, setModels] = useState([])
	const [modelsLoading, setModelsLoading] = useState(true)
	const [me, setMe] = useState({})

	const storage = useStorage();
	const remote = useRemote();
	const location = useLocation()
	const showPortalInfo = me && me.perms && me.perms.includes("VIEW_PORTAL_INFORMATION")

	useEffect(() => {
		storage.getOrFetch("/crm/tickers").then(setTickers)
		remote.get("/crm/tickers/models").then((resp) => {
			setModels(resp)
			setModelsLoading(false)
		});
		storage.getOrFetch("/crm/me").then(setMe)
	}, [])

	useEffect(() => {
		setData(craigsDocs)
		// if there is a searchQuery in the url, open the appropriate publication Settings
		if (craigsDocs && location && location.search) {
			let noteSettingsSearchParamArray = location.search
				.split(/[=,?]/g)
				.filter(val => val !== "" && val !== "note-settings")
				.map((val) => parseInt(val))
			setExpandedRows(noteSettingsSearchParamArray)
		}
	}, [JSON.stringify(craigsDocs)])


	const getImage = (t) => {
		const sector = gicsSectors.find(s => s.name === t)
		const theTicker = tickers && tickers.find(ticker => ticker.ric === t)

		if (theTicker) return theTicker.logo
		else if (sector) return sector.image
		else return ""
	}

	const headerWidths = showPortalInfo ? ["80px", "50px", "180px", "320px", "160px", "80px", "50px", "50px", "50px", "50px", "50px", "50px",] : ["130px", "180px", "320px", "160px", "80px", "50px", "50px", "50px", "50px", "50px", "50px",]
	const headers = showPortalInfo ? [
		"Date",
		"",
		"Coverage",
		"Title",
		"Author",
		"",
		{
			text: (
				<div className='sorting-header extra-space' onClick={() => filterOnclick ? filterOnclick("delivered") : null}>
					<Send className="colour-text-positive" size={16} />
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header extra-space' onClick={() => filterOnclick ? filterOnclick("failed") : null}>
					<AlertCircle className="colour-text-danger" size={16} />
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header extra-space' onClick={() => filterOnclick ? filterOnclick("read") : null}>
					<Mail className="colour-text-purple" size={16} />
				</div>
			)
		},
		getIcon("views", "16px"),
		getIcon("downloads", "16px"),
		getIcon("modalDownloads", "16px")] :
		[
			"Date",
			"Coverage",
			"Title",
			"Author",
			"",
			{
				text: (
					<div className='sorting-header extra-space' onClick={() => filterOnclick ? filterOnclick("delivered") : null}>
						<Send className="colour-text-positive" size={16} />
					</div>
				)
			},
			{
				text: (
					<div className='sorting-header extra-space' onClick={() => filterOnclick ? filterOnclick("failed") : null}>
						<AlertCircle className="colour-text-danger" size={16} />
					</div>
				)
			},
			{
				text: (
					<div className='sorting-header extra-space' onClick={() => filterOnclick ? filterOnclick("read") : null}>
						<Mail className="colour-text-purple" size={16} />
					</div>
				)
			},
			getIcon("views", "16px"),
			getIcon("downloads", "16px"),
			getIcon("modalDownloads", "16px")
		]

	const dataMap = data.map((document, idx) => {

		return <LibraryTableRow
			data={document}
			idx={idx}
			key={idx}
			tickers={tickers}
			expandedRows={expandedRows}
			setExpandedRows={setExpandedRows}
			getImage={getImage}
			onCraigsDocClick={onCraigsDocClick}
			onDeliveryClick={onDeliveryClick}
			models={models}
			modelsLoading={modelsLoading}
			showPortalInfo={showPortalInfo}
			filterOnclick={filterOnclick}
		/>
	});

	return (
		<>
			<Table
				id="craigs-library-table"
				headerWidths={headerWidths}
				minWidth={1250}
				headers={headers}
				dataMap={dataMap}
				// if no results, message gets rendered in RemoteData.js by withCraigsDocs wrapper.
				noResults={{wrapperStyle: { paddingTop: "2rem" }}} 
			/>
		</>
	);
}

export default withCraigsDocs(CraigsLibraryTable);

export const RawCraigsLibraryTable = CraigsLibraryTable;
