import React, { useRef, useState } from "react";
import { ChevronDown } from "react-feather";
import "./DropDown.scss";
import Popup from "./Popup";
import PopupMenu from "./PopupMenu";
import PopupMenuItem from "./PopupMenuItem";
import { useOutsideAlerter } from "../Utils/Utils";

/**
 * The `DropDown` function is a React component that creates a customizable dropdown menu with various
 * features like placeholder, filtering, sizing, and styling options.
 * 
 * @returns The `DropDown` component is being returned. It is a functional component that renders a
 * dropdown menu with various customizable features such as placeholder, filter label, size, label,
 * hover behavior, transparency, classNames, fixed positioning, clickability, styling, input dropdown,
 * value, and children elements. The dropdown menu is toggled by clicking on it or hovering over it,
 * and it displays a list of options
 */
const DropDown = ({ placeholder, filterLabel, size, label, onHover, transparent, classNames, fixed, clickable, style, inputDropdown, value, children, labelClassName, minMenuWidth, minDropdownWidth }) => {
	const [isActive, setIsActive] = useState(false);
	const [popupMenuWith, setPopupMenuWith] = useState(null)
	const wrapperRef = useRef(null);

	function onClick(itemClick, e) {
		itemClick && itemClick(e);
	}

	function showDropdown() {
		setIsActive(true);
	}

	function hideDropdown() {
		setTimeout(() => {
			setIsActive(false)
		}, 100);
	}

	function handleKeyPress(event) {
		if (event.key === 'Escape') {
			hideDropdown()
		}
	}


	let localClassNames = "dropdown-container";
	let allocationDropdown;

	if (classNames) {
		localClassNames += " " + classNames;
	}
	if (placeholder) {
		localClassNames += " placeholder";
	}
	if (transparent) {
		localClassNames += " transparent";
	}
	if (filterLabel) {
		allocationDropdown = <div className="filter-inner-label">{filterLabel}</div>;
	}
	if (size) {
		localClassNames += " dropdown-size-" + size;
	}

	return (
		<div ref={wrapperRef}>
			{label && <label className={`label-input${labelClassName ? ' ' + labelClassName : ''}`}>{label}</label>}
			<Popup
				clickAway
				hoverOnly={onHover}
				absolute={!fixed}
				relative={!fixed}
				popupOuterWidth={fixed ? "" : "100%"}
				setPopupMenuWith={setPopupMenuWith}
				triggerElement={
					<div
						onBlur={hideDropdown}
						className={localClassNames}
						onClick={isActive ? hideDropdown : showDropdown}
						onMouseEnter={clickable ? null : showDropdown}
						onMouseLeave={clickable ? null : hideDropdown}
						style={style ? style : null}
						tabIndex={0}
						onKeyDown={handleKeyPress}
					>
						{allocationDropdown}
						<div
							className={`dropdown-value-wrapper flex-center${isActive ? " dropdown-value__active" : ""}`}
							style={inputDropdown ? { padding: "9px 12px" } : minDropdownWidth ? {minWidth: minDropdownWidth} : null}
						>
							{value ? value : "Select"}
							<ChevronDown className="dropdown-chevron" />
						</div>
					</div>
				}
				popupElement={
					<PopupMenu width={minMenuWidth && minMenuWidth > popupMenuWith ? minMenuWidth : popupMenuWith}>
						{children.map((child, idx) => {
							return React.cloneElement(<PopupMenuItem noRadius label={child} key={idx} onClick={(e) => onClick(child.props.onClick, e)} value={value} selected={value === child.props.children}/>);
						})}
					</PopupMenu>
				}
			/>
		</div>
	);
}


export default DropDown;
