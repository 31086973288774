import React, { useState } from 'react';
import { ChevronRight } from 'react-feather';
import { useRemote } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import { toastSuccess } from '../../../components/popups/Toast';
import "./AssetClass.scss"


const AssetActionsPopup = ({ move, edit, remove, classes, asset, setImageUpload, setShowImageEditModal, setEditedAsset, allAssets, callbackFunction, newClass, moveCallback }) => {
    const remote = useRemote()
    const [moving, setMoving] = useState(false)

    function editLogo() {
        setEditedAsset(asset)
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true;
        fileInput.onchange = (event) => { console.log('event.target.files', event.target.files); setImageUpload(event.target.files); setShowImageEditModal(asset.id) };
        fileInput.click();
    };

    function moveAsset(clss) {
        const assetsOnClass = allAssets.filter(a => a.assetClassId === clss.id)
        const newList = [...assetsOnClass, { ...asset, assetClassId: clss.id }]
        const payload = {
            id: clss.id,
            name: clss.name,
            assets: newList.sort((a, b) => a.name.localeCompare(b.name))
        }
        remote.put(`/assets/classes/${clss.id}`, payload).then((resp) => {
            if (newClass) {
                moveCallback(asset.id)
                toastSuccess(`Asset has been moved to ${clss.name} `)
            } else {
                callbackFunction()
                toastSuccess(`Asset has been moved to ${clss.name} `)
            }
        })
    }

    function removeAsset() {
        const assetsOnClass = allAssets.filter(a => a.assetClassId === asset.assetClassId && a.id !== asset.id)
        const payload = {
            id: asset.assetClassId,
            name: classes.find(c => c.id === asset.assetClassId).name,
            assets: assetsOnClass
        }
        remote.put(`/assets/classes/${asset.assetClassId}`, payload).then((resp) => {
            callbackFunction()
            toastSuccess(`Asset has been removed from ${classes.find(c => c.id === asset.assetClassId).name} `)
        })
    }


    return (<>
        <ul className='assets-actions-popup-wrapper'>
            {move &&
                <Tooltip padding='none' placement='left-start' outerStyle={{ width: '100%' }} label={
                    <ul className='assets-actions-popup-wrapper' onMouseEnter={() => setMoving(true)} onMouseLeave={() => setMoving(false)}>
                        {classes.map(c => <li className='assets-action-label' onClick={() => moveAsset(c)}>{c.name}</li>)}
                    </ul>
                }>
                    <li className={`assets-action-label${moving ? ' moving-through' : ''}`}>
                        <div className='move-to'>
                            Move to
                            <ChevronRight size={20} />
                        </div>
                    </li>
                </Tooltip>
            }
            {edit && <li onClick={editLogo} className='assets-action-label'>
                Edit logo
            </li>}
            {remove && <li onClick={removeAsset} className='assets-action-label'>
                Remove from class
            </li>}
        </ul>
    </>);
}

export default AssetActionsPopup