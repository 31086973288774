import React, { useEffect, useState } from 'react';
import { useRemote } from '../../Utils/Utils';
import LoadingIcon from '../../components/LoadingIcon';
import { ArrowRight, CheckCircle } from 'react-feather';
import { Link } from 'react-router-dom';


const WorkflowDataSynchronisation = ({ workflowId, taskId, isComplete }) => {

    const [data, setData] = useState(null);
    const remote = useRemote()

    useEffect(() => {
        remote.get(`workflows/workflow/${workflowId}/workflowTask/${taskId}`).then((res) => {
            setData(res);
        })

        const interval = setInterval(() => {
            remote.get(`workflows/workflow/${workflowId}/workflowTask/${taskId}`).then((res) => {
                setData(res);
            })
        }, 30000)

        return () => clearInterval(interval)
    }, [])

    if (!data) return <><LoadingIcon centered /></>

    return (
        <>
            {data.data.map((offer) => {
                return (
                    <>
                        {isComplete
                            ? <p className='all-tasks-completed'>Data Synchronisation complete <CheckCircle color='#56D1C3' /></p>
                            : offer.missing && offer.missing.length > 0
                                ? <>
                                    <p style={{ fontSize: "12px", opacity: 0.5, lineHeight: "12px", marginBottom: "1rem", marginTop: "1rem" }} >{offer.offerName}</p>
                                    <Link to="/admin/data" className='mb-m text-danger-dark flex-align-center' >
                                        <span className='text-bold mr-xs'>Missing Data</span>
                                        <span className="mr-s" >{` (${offer.missing.length})`}</span>
                                        <ArrowRight color="#A0001E" size={20} />
                                    </Link >
                                    <div className='worflow-data-synchronisation-task'>
                                        {offer.missing.map((fund, idx) => {
                                            return <Link className={`missing-fund ${idx == offer.missing.length - 1 ? "mb-xl" : ""}`} to="/admin/data" >{fund}</Link>
                                        })}
                                    </div>
                                </>
                                : <>
                                    <p style={{ fontSize: "12px", opacity: 0.5, lineHeight: "12px", marginBottom: "1rem", marginTop: "1rem" }} >{offer.offerName}</p>
                                    <div className='mb-m'><span className='text-bold'>No Missing Data</span></div>
                                </>
                        }

                    </>
                )
            })}
        </>
    )
}

export default WorkflowDataSynchronisation