import ClassedElement from "../../../components/ClassedElement";
import React from "react";
import "./Page.scss";
import "./PageContentBanner.scss";
import "./PageContentFilters.scss";
import useWindowDimensions from "../../../Utils/Utils";

const ClassedDiv = (className) => ClassedElement(className, "div");
const withContentInner = function (WrappedComponent) {
	return ({ children, ...otherProps }) => (
		<WrappedComponent {...otherProps}>
			{children}
		</WrappedComponent>
	);
};

const Page = ({fixedBanner, noBanner, id, showScrollbar, children, style, noOverflownY}) => {
	return (
		<div id={id} className={`page-content-outer${fixedBanner ? " fixed-banner" : noBanner ? " no-banner" : ""}${showScrollbar ? "" : " hide-scrollbar"}${noOverflownY ? " no-overflown-y" : ""}`} style={style}>
			{children}
		</div>
	)
}

export const PageContentBanner = (props) => {
	const { children, divider, justified, gap, align, fixed } = props;
	return (
		<div className={`page-content-banner transition-fade-in${divider ? " page-content-banner-divider" : ""}${justified ? (" page-content-justified-" + justified) : ""}${gap ? " gap-" + gap : ""}${align ? (" align-items-" + align) : ""}${fixed? ' page-content-banner-fixed' : ''}`}>
			{children}
		</div>
	)
}

export const PageContentBodyGrid = ({
	children,
	className,
	rowGap,
	columnGap,
	forwardRef,
	id,
	gridColCount,
	subGrid,
	paddingBottom,
	paddingTop,
	splitScroll,
	divider,
	showScrollbar,
	style,
	autoRows,
	noOverflownY,
	gridTemplateColumns
}) => {
	const { width } = useWindowDimensions();

	return (
		<div
			ref={forwardRef}
			id={id}
			className={`page-content-body-grid transition-fade-in-slow${divider ? " grid-divider-" + divider : ""}${className ? " " + className : ""}${
				rowGap ? " row-gap-" + rowGap : ""
			}${columnGap ? " column-gap-" + columnGap : ""}${splitScroll && width > 1200 ? " grid-split-scroll" : ""}${showScrollbar ? "" : " hide-scrollbar"}${noOverflownY ? " no-overflown-y" : ""}`}
			style={{
				...style,
				gridAutoRows: autoRows ? autoRows : "",
				gridTemplateColumns: gridTemplateColumns ? gridTemplateColumns : "repeat(" + gridColCount + ", minmax(0, 1fr))",
				paddingBottom: paddingBottom ? paddingBottom : subGrid ? "0" : null,
				paddingTop: paddingTop ? paddingTop : subGrid && divider !== "top" ? "0" : null,
			}}
		>
			{children}
		</div>
	);
};

export default Page;
export const PageContent = ClassedDiv("page-content");
export const PageContentInner = ClassedDiv("page-content-inner");
export const PageContentFilters = withContentInner(ClassedDiv("page-content-filters"));