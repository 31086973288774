import React from 'react';
import StatusToken from '../../StatusTokens';
import SidebarExtensionWrapper from './SideBarExtensionWrapper';
import SideBarSection from './SideBarSection';
import Divider from '../../Divider';
import CommentsSection from '../../Comments/CommentsSection';

const CommentsSidebarExtension = ({ slideIn, slideOut, compliance, peerReview, data }) => {
	const getTokenState = (state) => {
		if (state.granted) { return "positive" }
		else if (state.responded) { return "warning" }
		else if (state.requested) { return "warning" }
		else if (state.denied) { return "danger" }
		else { return "" }
	}
	const getTokenMessage = (state) => {
		if (state.granted) { return "Approved" }
		else if (state.responded) { return "Responded" }
		else if (state.requested) { return "Requested" }
		else if (state.denied) { return "Denied" }
		else { return "Not Requested" }
	}


	return (
		<SidebarExtensionWrapper position="right" shadow slideIn={slideIn} slideOut={slideOut} noPadding>
			<SideBarSection>
				<div className="flex-align-center">
					<h4 className="mr-s">Compliance</h4>
					<StatusToken type={getTokenState(compliance)}>{getTokenMessage(compliance)}</StatusToken>
				</div>
			</SideBarSection>
			<SideBarSection separator>
				<div className="flex-align-center">
					<h4 className="mr-s">Peer Review</h4>
					<StatusToken type={getTokenState(peerReview)}>{getTokenMessage(peerReview)}</StatusToken>
				</div>
			</SideBarSection>
			<Divider />
			<SideBarSection>
				{data && data.ticker && data.type && <CommentsSection ticker={data && data.ticker} templateName={data && data.type} compliance={compliance} />}
			</SideBarSection>
		</SidebarExtensionWrapper>
	);
}

export default CommentsSidebarExtension;