import React, { useState, useEffect } from 'react';
import { useRemote } from '../../../Utils/Utils';
import { useParams, useHistory } from 'react-router-dom';
import { FileText, CreditCard, ChevronDown, ChevronUp, Trash2 } from 'react-feather';
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';
import { differenceInMonths, differenceInYears, format, formatDuration, subYears } from "date-fns";
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import RiskIndicator from './RiskIndicator'
import DetailTextbox from './DetailTextbox'
import Table from '../../../components/Table/Table';
import Button from '../../../components/Buttons/Button';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import FlexWrapper from '../../FlexWrapper';
import PageContentToggle from '../../../components/Menus/PageContentToggle';
import Checkbox from '../../../components/Checkbox';
import Modal from '../../../components/Modals/Modal';
import Tooltip from '../../../components/popups/Tooltip';
import "./FundPage.scss"


const FundPage = () => {
    const remote = useRemote()
    const { fundId } = useParams()
    const identifier = btoa(fundId)
    const history = useHistory()

    const [originalFund, setOriginalFund] = useState(null)
    const [fund, setFund] = useState(null)
    const [personExpanded, setPersonExpanded] = useState(null)
    const [tableWidth, setTablwWidth] = useState()
    const [editMode, setEditMode] = useState(false)
    const [selectedTab, setSelectedTab] = useState('personnel')
    const [notes, setNotes] = useState([])
    const [taggedNotesIds, setTaggedNotesIds] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [taggedNotesToDelete, setTaggedNotesToDelete] = useState([])
    const [taggedNotes, setTaggedNotes] = useState([])
    const [extra, setExtra] = useState([])


    useEffect(() => {
        remote.get(`/funds/funds/${fundId}`).then((fund) => {
            setOriginalFund(fund)
            setFund(fund)
            setNotes(fund.disclosures)
            // TODO: create a way to get the right template name or a way to store the tagged notes on the fund
            remote.get("/retrievev2/" + identifier + "/Simplicity QFU").then((res) => checkTaggedNotes(res))
            remote.get(`/funds/funds/${fundId}/extra`).then(setExtra);
        })
    }, [remote, fundId])
    
    useEffect(() => {
        window.addEventListener("resize", getTableWidth);
        return () => window.removeEventListener("resize", getTableWidth);
    }, [])

    function getTableWidth() {
        let tableElement = document.getElementById('fund-personnel-table')
        if (tableElement) {
            setTablwWidth(tableElement.offsetWidth)
        }
    }

    function updateFund(newValue, property) {
        // save temporary updates
        const newObj = { ...fund, [property]: newValue }
        setFund(newObj)
    }

    function updateKeyPersonnel(newValue, property, idx) {
        if (property === "deleted" && newValue === true && fund.personnel[idx].id === null) {
            let newPersonnel = [...fund.personnel.filter((person, i) => i !== idx)]
            const newObj = { ...fund, personnel: newPersonnel }
            setFund(newObj)
            setPersonExpanded(newPersonnel.length - 1)
            return;
        }
        // save temporary updates
        let newPersonnel = []
        fund.personnel.forEach((person, i) => {
            if (i === idx) {
                newPersonnel.push({ ...person, [property]: newValue })
            } else {
                newPersonnel.push(person)
            }
        })
        const newObj = { ...fund, personnel: newPersonnel }
        setFund(newObj)
    }

    function saveEdits() {
        // Save personnel records first
        saveEditedPersonnel().then(() => {
            // Then save the fund
            remote.post(`/funds/funds/${fundId}`, fund).then(updatedFund => {
                setOriginalFund(updatedFund);
                setFund(updatedFund);
            });
            setEditMode(false);
        }).then(() => {
            if (taggedNotesToDelete && taggedNotesToDelete.length > 0) untaggNotes()
            else toastSuccess("Changes saved");
        }).catch(error => {
            toastDanger("Failed to save changes");
            console.error("Failed to save personnel records:", error);
        });
    }

    function cancelEdits() {
        setFund(originalFund)
        setNotes(originalFund.disclosures)
        setEditMode(false)
    }

    function formatFundValue(value) {
        const numberValue = parseFloat(value);
        const formattedValue = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(numberValue);
        return formattedValue;
    }

    function getFormattedDuration(startDate, endDate) {
        if (!startDate || !endDate) {
            return "Could not calculate time in position.";
        }
        const years = differenceInYears(endDate, startDate);
        const totalMonths = differenceInMonths(endDate, startDate);
        const months = totalMonths % 12;

        const duration = {
            years,
            months
        };

        return formatDuration(duration);
    }

    function saveEditedPersonnel() {
        if (!originalFund || !fund) return Promise.resolve();

        const changedRecords = fund.personnel.filter((person, idx) => {
            const originalPerson = originalFund.personnel[idx];
            return JSON.stringify(person) !== JSON.stringify(originalPerson);
        });

        const updatePromises = changedRecords.map((person) => {
            return updatePersonnelRecord(person);
        });

        return Promise.all(updatePromises);
    }

    async function updatePersonnelRecord(person) {
        if (!person) return Promise.resolve();

        if (person.id === null) {
            await createNewPerson(person)
        } else if (person.deleted === true) {
            await deletePersonnelRecord(person)
        } else {
            await remote.put(`/funds/funds/personnel/${person.id}`, {
                name: person.name,
                currentPosition: person.currentPosition,
                currentStartDate: person.currentStartDate,
                previousPosition: person.previousPosition,
                previousStartDate: person.previousStartDate,
                previousEndDate: person.previousEndDate
            }).catch((error) => {
                console.error(`Failed to update personnel record for ${person.name}:`, error);
            });
        }
    }

    function createTempPerson() {
        let newPersonnel = [...fund.personnel]
        newPersonnel.push({
            name: "",
            currentPosition: "",
            currentStartDate: format(subYears(new Date(), 1), "yyyy-MM-dd"),
            previousPosition: "",
            previousStartDate: format(subYears(new Date(), 2), "yyyy-MM-dd"),
            previousEndDate: format(subYears(new Date(), 1), "yyyy-MM-dd"),
            id: null
        })
        const newObj = { ...fund, personnel: newPersonnel }
        setFund(newObj)
        setPersonExpanded(newPersonnel.length - 1)
    }

    async function createNewPerson(person) {
        await remote.post("/funds/funds/personnel", {
            ...person,
            funds: [fundId]
        })
    }

    async function deletePersonnelRecord(person) {
        // DELETE / funds / personnel / { personId } / fund / { fund }
        await remote.delete(`/funds/funds/personnel/${person.id}/fund/${fundId}`).then(() => {
            let newPersonnel = []
            fund.personnel.forEach((p) => {
                if (p.id !== person.id) {
                    newPersonnel.push(p)
                }
            })
            setFund({ ...fund, personnel: newPersonnel })
        })
    }

    const incompletePersonnelExists = fund?.personnel?.some(person => {
        return !person.name || !person.currentPosition || !person.currentStartDate || !person.previousPosition || !person.previousStartDate || !person.previousEndDate;
    });

    const colWidths = ['auto', '25px']

    const extraInfoDataMap = (data) => data && data.map(datum => {
        return <tr key={datum.id}>
            <td>{datum.key}</td>
            <td>{datum.value}</td>
        </tr>
    })

    const personnelDataMap = (data) => data && data.map((person, idx) => {
        const { name, email, currentPosition, previousPosition, currentStartDate, previousStartDate, previousEndDate, deleted } = person
        const personIdOrNull = personExpanded === idx ? null : idx
        return (
            <>
                <tr key={idx} className='cursor-pointer' onClick={() => null/*changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/fund-management/funds/' + fund.id)*/}>
                    <td className={`${personExpanded === idx ? "expanded-table-row" : ""} ${deleted ? "deleted-personnel" : ""}`} onClick={() => { setPersonExpanded(personIdOrNull) }}  >
                        <FlexWrapper flexDirection="row" justify="space-between" gap="m" >
                            {editMode && !deleted
                                ? <>
                                    <DetailField
                                        onClick={(e) => e.stopPropagation()}
                                        variant="bordered-light"
                                        editMode={editMode}
                                        onChange={(e) => updateKeyPersonnel(e.target.value, "name", idx)}
                                        value={name}
                                        style={{ flexGrow: 1 }}
                                    />

                                </>
                                : <span>
                                    <span style={deleted ? { color: "#A0001E", opacity: "0.4" } : {}}>
                                        {name}
                                    </span>
                                    <span style={{ color: deleted ? "#A0001E" : '#ADB1BD', fontSize: '0.85rem', paddingLeft: "0.25rem", opacity: deleted ? 0.4 : 1 }}>
                                        {deleted ? "(Deleting)" : ""}
                                    </span>
                                </span>
                            }
                            {editMode || deleted ? <Trash2 className="material-icons" style={deleted ? { color: "#A0001E", opacity: 1 } : { opacity: 0.8 }} onClick={(e) => { e.stopPropagation(); updateKeyPersonnel(!deleted, "deleted", idx) }} /> : ""}
                        </FlexWrapper>
                    </td>
                    <td className={`${personExpanded === idx ? "expanded-table-row" : ""} ${deleted ? "deleted-personnel" : ""}`} onClick={() => setPersonExpanded(personIdOrNull)} style={{ textOverflow: "clip" }}>
                        {personExpanded === idx && !deleted
                            ? <ChevronUp size={20} color='#BFC1CB' style={deleted ? { opacity: 0.4 } : {}} />
                            : <ChevronDown size={20} color='#BFC1CB' style={deleted ? { opacity: 0.4 } : {}} />
                        }
                    </td>
                </tr>
                {personExpanded === idx && !deleted ?
                    <tr className="fund-page-expanded-key-person">
                        <td colSpan="2" style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <PageContentBodyGrid rowGap="xl" gridColCount="6" paddingBottom='2rem' style={{ padding: '1rem 0.5rem', backgroundColor: '#fbfcfe', width: tableWidth }}>
                                <PageGridItem col="1 / span 3">
                                    <DetailField
                                        variant="bordered-light"
                                        editMode={editMode && !deleted}
                                        onChange={(e) => updateKeyPersonnel(e.target.value, "currentPosition", idx)}
                                        label="Current Position"
                                        value={currentPosition}
                                    />
                                </PageGridItem>
                                <PageGridItem col="4 / span 3">
                                    <DetailField
                                        type="date"
                                        max={format(new Date(), "yyyy-MM-dd")}
                                        variant="bordered-light"
                                        editMode={editMode}
                                        onChange={(e) => updateKeyPersonnel(e.target.value, "currentStartDate", idx)}
                                        label={editMode ? "Current Position Start Date" : "Time in Current Position"}
                                        value={editMode ? currentStartDate : getFormattedDuration(new Date(currentStartDate), new Date())}
                                    />

                                </PageGridItem>
                                <PageGridItem col="1 / span 3">
                                    <DetailField
                                        variant="bordered-light"
                                        editMode={editMode && !deleted}
                                        onChange={(e) => updateKeyPersonnel(e.target.value, "previousPosition", idx)}
                                        label="Previous/Other Position"
                                        value={previousPosition}
                                    />
                                </PageGridItem>
                                <PageGridItem col="4 / span 3">
                                    <DetailField
                                        type="date"
                                        max={format(new Date(), "yyyy-MM-dd")}
                                        variant="bordered-light"
                                        editMode={editMode}
                                        onChange={(e) => { updateKeyPersonnel(e.target.value, "previousStartDate", idx) }}
                                        label={editMode ? "Previous Position Start Date" : "Time in Current Position"}
                                        value={editMode ? previousStartDate : getFormattedDuration(new Date(previousStartDate), new Date(previousEndDate))}
                                    />
                                </PageGridItem>
                                {editMode && <PageGridItem col="1 / span 3">
                                    <DetailField
                                        type="date"
                                        max={format(new Date(), "yyyy-MM-dd")}
                                        variant="bordered-light"
                                        editMode={editMode}
                                        onChange={(e) => updateKeyPersonnel(e.target.value, "previousEndDate", idx)}
                                        label="Previous Position End Date"
                                        value={previousEndDate}
                                    />
                                </PageGridItem>
                                }
                            </PageContentBodyGrid>
                        </td>
                    </tr>
                    : ""
                }
            </>
        );
    })

    function updateNote(boolean, id) {
        const newList = notes.map(note => {
            if (note.id === id) {
                const updatedNote = { ...note, selected: boolean }
                return updatedNote
            } else return note
        })
        setNotes(newList)
        setFund({ ...fund, disclosures: newList })
    }

    function untaggNotes() {
        const newNoteList = taggedNotes.filter(n => !taggedNotesToDelete.includes(n.offerNoteId))

        const footnoteIdToIndex = newNoteList.reduce((acc, item, index) => {
            acc[item.footnoteId] = index + 1;
            return acc;
        }, {});

        const sortedNotes = newNoteList
            .map(note => ({ ...note, superscript: footnoteIdToIndex[note.footnoteId] }))
            .sort((a, b) => a.superscript - b.superscript);

        const payload = { FOOTNOTES: JSON.stringify(sortedNotes) }

        remote.put("/savev2/" + identifier + "/Simplicity QFU", payload).then(response => {
            setTaggedNotes(newNoteList)
            setTaggedNotesIds(newNoteList.map(n => n.offerNoteId))
            setTaggedNotesToDelete([])
        })
    }

    function checkTaggedNotes(res) {
        if (res && res.FOOTNOTES) {
            const parsedNotes = JSON.parse(res?.FOOTNOTES)
            const taggedNotesArray = parsedNotes && parsedNotes.length > 0 && parsedNotes.map(n => n.offerNoteId)
            setTaggedNotes(parsedNotes)
            setTaggedNotesIds(taggedNotesArray)
        }
    }

    function disclosureList(data) {
        if (data.length > 0) {
            const hasSelected = data.find(n => n.selected)
            if (!hasSelected && !editMode) {
                return <p>No notes selected for this fund. <span className='text-link' onClick={() => setEditMode(true)}>Select some notes</span></p>
            } else {
                return data.map(note => {
                    const { disclosure, id, selected } = note
                    const isTagged = taggedNotesIds && taggedNotesIds.length > 0 && taggedNotesIds.includes(id)
                    return (
                        (editMode || (!editMode && selected)) &&
                        <PageGridItem container direction='row' gap='s' alignItems='center' style={{ paddingBottom: '1rem' }}>
                            {editMode &&
                                <FlexWrapper direction="column" style={{ height: "100%", width: 24 }}>
                                    <Checkbox checked={selected}
                                        onChange={() => {
                                            if (isTagged && selected) {
                                                setTaggedNotesToDelete(taggedNotesToDelete && taggedNotesToDelete.length > 0 ? [...taggedNotesToDelete, id] : [id])
                                                setShowModal(true)
                                            } else if (isTagged && !selected) {
                                                updateNote(true, id)
                                                const filteredList = taggedNotesToDelete.filter(n => n.offerNoteId !== id)
                                                setTaggedNotesToDelete(filteredList)
                                            } else updateNote(!selected, id)
                                        }}
                                        className="delete-icon no-margin"
                                    />
                                    {isTagged && selected &&
                                        <Tooltip placement="left" label="Note in use">
                                            <span className={`material-symbols-outlined disclosure-pin-icon pinned-note`} >keep</span>
                                        </Tooltip>
                                    }
                                </FlexWrapper>
                            }
                            <DetailTextbox
                                value={disclosure}
                                update={() => null}
                                editMode={false}
                                placeholder={'Add notes'}
                                minHeight={50}
                                resize='vertical'
                                className='box-style'
                                boxStyle
                                fullWidth
                                divClassName={!selected ? 'no-selected-box' : null}
                            />
                        </PageGridItem>
                    )
                })
            }
        }
    }


    return (
        <Page noBanner>
            <PageContentBodyGrid id="fund-page" gridColCount="12" subGrid paddingBottom="2.5rem">
                <PageGridItem gap='l' direction='col' col="1 / span 12" >
                    <ActionButtonsBanner
                        className="pt-l"
                        hideBackButton
                        editMode={editMode}
                        edit={{ onClick: () => setEditMode(true) }}
                        duplicate={{ disabled: true }}
                        remove={{ disabled: true }}
                        cancel={{ onClick: () => { cancelEdits() } }}
                        save={{ onClick: () => { saveEdits() }, disabled: incompletePersonnelExists }}
                    />
                    <PageGridDivider />
                </PageGridItem>
                <PageGridItem container col="1 / span 6" direction='col' gap="xl" style={{ display: 'flex', paddingRight: '1.5rem' }}>
                    <PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
                        <PageGridItem container col="1 / span 6">
                            <DetailSectionHeader header="Fund Details" icon={<FileText />} />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "fundName")}
                                label="Fund Name"
                                value={fund?.fundName}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "fundNumber")}
                                label="Fund Number"
                                value={fund?.fundNumber}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "offerName")}
                                label="Offer Name"
                                value={fund?.offerName}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "offerNumber")}
                                label="Offer Number"
                                value={fund?.offerNumber}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "disclosure")}
                                label="Period disclosure applies, if applicable"
                                value={fund?.disclosure}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "classification")}
                                label="Fund Classification"
                                value={fund?.classification}
                            />
                        </PageGridItem>
                        <PageGridItem container col="1 / span 6">
                            <RiskIndicator risk={+fund?.riskIndicator} updateFund={updateFund} editMode={editMode} />
                        </PageGridItem>
                        <PageGridItem container col="1 / span 6">
                            <DetailTextbox
                                value={fund?.fundDescription}
                                update={(e) => updateFund(e.target.value, 'fundDescription')}
                                editMode={editMode}
                                placeholder='Add a description'
                                label='Fund Description'
                                minHeight={100}
                                resize='vertical'
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "totalValue")}
                                label="Total fund value as per last fund update"
                                value={fund && fund.totalValue ? formatFundValue(fund.totalValue) : '-'}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "currency")}
                                label="Currency of fund value"
                                value={fund?.currency}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "numberOfInvestors")}
                                label="Number of Investors"
                                value={fund?.numberOfInvestors}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateFund(e.target.value, "dateFundStarted")}
                                label="Date fund started"
                                value={fund?.dateFundStarted}
                            />
                        </PageGridItem>
                    </PageContentBodyGrid>
                </PageGridItem>
                <PageGridItem container col="7 / span 6">
                    <PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid divider='left'>
                        <PageGridItem container col="1 / span 6">
                            <DetailSectionHeader header="Fees" icon={<CreditCard />} />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={false}
                                onChange={(e) => null}
                                label="Performance fees"
                                value={fund?.performanceFeesApply ? "Yes" : "No"}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={false}
                                onChange={(e) => null}
                                label="Individual Action Charges"
                                value={fund?.individualChargesApply ? "Yes" : "No"}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                editMode={false}
                                onChange={(e) => null}
                                label="Total annual fund charge (%)"
                                value={fund?.totalAnnualCharge + "%"}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 6">
                            <PageGridDivider />
                        </PageGridItem>
                        <PageGridItem container col="1 / span 6" gap='l' direction='col'>
                            <FlexWrapper flexDirection="row" justify="space-between" style={{ height: 32 }}>
                                <PageContentToggle labels={[
                                    { label: "Key Personnel", active: selectedTab === "personnel", onClick: () => setSelectedTab("personnel") },
                                    { label: "Disclosure", active: selectedTab === "disclosure", onClick: () => setSelectedTab("disclosure") },
                                    { label: "Other data", active: selectedTab === "other", onClick: () => setSelectedTab("other") },
                                ]} />
                                {selectedTab === "personnel" && <Button disabled={!editMode} onClick={() => createTempPerson()}>Add Personnel</Button>}
                            </FlexWrapper>
                            {selectedTab === "personnel" &&
                                <Table
                                    minWidth={300}
                                    colWidths={colWidths}
                                    dataMap={personnelDataMap(fund?.personnel)}
                                    id='fund-personnel-table'
                                />
                            }
                            <div>
                                {selectedTab === "disclosure" &&
                                    <>
                                        {notes.length === 0 ? <p>No notes found on offer {fund.offerNumber}. <span onClick={() => history.push('/admin/fund-management/offers/' + fund.offerNumber)} className='text-link' href=''>Add some notes</span></p> : ""}
                                        {disclosureList(notes)}
                                    </>
                                }
                            </div>
                            <div>
                                {selectedTab === "other" &&
                                    <>
                                        <Table
                                            minWidth={300}
                                            colWidths={['250px','auto']}
                                            dataMap={extraInfoDataMap(extra)}
                                            id='fund-extra-info-table'
                                        />
                                    </>
                                }
                            </div>
                        </PageGridItem>
                    </PageContentBodyGrid>
                </PageGridItem>
            </PageContentBodyGrid>
            {showModal && <Modal
                header="Are you sure?"
                cancelButton={{ onClick: () => { setShowModal(false); setTaggedNotesToDelete([]) } }}
                confirmButton={{ onClick: () => { setShowModal(false); updateNote(false, taggedNotesToDelete[taggedNotesToDelete.length - 1]) } }}
            >
                <p>This note is currently in use and will be removed from any templates using it once your changes are saved.</p>
            </Modal>}
        </Page>
    );
}

export default FundPage;