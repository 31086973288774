import React, { Component } from "react";
import "./RadioInputGroup.scss";
import RadioInput from "./RadioInput";

class RadioInputGroup extends Component {
	state = {};
	render() {
		const { groupLabel, options, onChange, value, toggleable, clear, invalid } = this.props;

		return (
			<div className="radio-input-group">
				<div className="filter-heading">{groupLabel}</div>
				<div className="radio-fields">
					{options && options.map((option, key) => <RadioInput invalid={invalid} key={key} label={option} checked={option === value} onClick={() => (toggleable && option === value ? clear() : onChange(option))} />)}
				</div>
			</div>
		);
	}
}

export default RadioInputGroup;
