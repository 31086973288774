import React, { useEffect, useState } from 'react';
// import { PageContentBodyGrid } from '../Shared/Page/Page';
import PageGridItem from '../Shared/Page/PageGridItem';
import PageGridDivider from '../Shared/Page/PageGridDivider';
import Table from '../../components/Table/Table';
import TextInput from '../../components/Input/TextInput';
import DateRangeInput from '../CraigsLibraryPage/DateRangeInput';
import { Search } from 'react-feather';
import FlexWrapper from '../FlexWrapper';
import { formatRelative, isAfter, isBefore, parseISO, subDays, subHours } from 'date-fns';
import ProgressBar from '../../components/Input/ProgressBar';
import { useHistory } from 'react-router-dom';
import { PAGES, ROUTE_PATHS } from '../../InternalApiApp';
import { useRemote, useStorage } from '../../Utils/Utils';
import moment from 'moment';
import { statuses } from './WorkflowDetails';

const headers = ["Process", "Last Update", "Progress"]

const WorkflowsList = ({ }) => {
	const [workflows, setWorkflows] = useState([]);
	const [processFilter, setProcessFilter] = useState("");
	const [dateRange, setDateRange] = useState({ from: null, to: null });

	const history = useHistory()
	const remote = useRemote()
	const storage = useStorage();

	useEffect(() => {
		storage.refresh("workflows/list").then((res) => {
			setWorkflows(res);
		})
	}, []);

	const filterRows = (workflow) => {
		// filter process
		if (processFilter && !workflow.processName.toLowerCase().includes(processFilter.toLowerCase())) return false;
		// filter by date
		if (dateRange.from || dateRange.to) {
			const lastUpdated = parseISO(workflow.lastUpdatedAt);
			if (
				(dateRange.from && moment(lastUpdated).isSameOrBefore(dateRange.from.format())) ||
				(dateRange.to && moment(lastUpdated).isSameOrAfter(dateRange.to.format()))
			) return false;
		}
		return true
	};

	const getRows = () => {
		if (!workflows || workflows.length === 0) return <></>;

		return workflows.filter(filterRows).map((workflow, idx) => {
			let workflowTaskProgress = 0
			const workflowTaskTotal = workflow?.tasks?.length || 0
			workflow?.tasks?.forEach((task, idx) => {
				if (statuses[task.status] === statuses.COMPLETE) {
					workflowTaskProgress += 1
				}
			})
			return (
				<tr key={"workflow-" + workflow.processName + "-" + idx} style={{ cursor: "pointer" }} onClick={() => history.push(`${ROUTE_PATHS[PAGES.ACTIVE_WORKFLOWS]}/${workflow.id}`)} >
					<td>{workflow.processName}</td>
					<td>{formatRelative(new Date(parseISO(workflow.lastUpdatedAt)), new Date())}</td>
					<td>
						<FlexWrapper direction="column" style={{ maxWidth: "300px", paddingRight: "1rem", gap: "15px" }}>
							<ProgressBar noToolTip extent={workflowTaskTotal} value={workflowTaskProgress} />
						</FlexWrapper>
					</td>
				</tr>
			);
		});
	}

	return (
		<PageGridItem colSpan='12' gap='xl' direction='col'>
			<PageGridItem direction='row' gap='l' alignItems='center'>
				<TextInput
					placeholder="QFU, SIPO"
					value={processFilter}
					onChange={(e) => setProcessFilter(e.target.value)}
					icon={<Search />}
					size="small"
				/>
				<DateRangeInput
					onChange={(dateRange) => setDateRange(dateRange)}
					value={dateRange}
				/>
			</PageGridItem>
			<PageGridDivider />
			<PageGridItem fullWidth showScrollbar>
				<Table headers={headers} dataMap={getRows()} />
			</PageGridItem>
		</PageGridItem>
	);
}

export default WorkflowsList;