import React, { useState, useEffect } from "react";
import { useStorage, handleDraftFiltering } from '../../Utils/Utils';
import { gicsSectors } from '../../Utils/DummyData';
import Table from "../../components/Table/Table"
import DraftsTableRow from "./DraftsTableRow";

const DraftsLibraryTable = ({ setDraftsLoading, filters }) => {
	const [data, setData] = useState([])
	const [expandedRows, setExpandedRows] = useState([])
	const [tickers, setTickers] = useState([])

	const storage = useStorage();


	useEffect(() => {
		storage.getOrFetch("/crm/tickers").then(setTickers)
		storage.refresh("/documents/drafts")
			.then(filterSortAndSetData)
			.catch((err) => {
				console.error(err)
				setData([])
				setDraftsLoading(false)
			});;
		storage.watch("/documents/drafts", filterSortAndSetData);
		return () => storage.unwatch("/documents/drafts")
	}, [])

	useEffect(() => {
		refreshList()
	}, [filters])

	const refreshList = () => storage.refresh("/documents/drafts").then(filterSortAndSetData)

	const filterSortAndSetData = (drafts) => {
		let sortedDrafts = drafts?.filter((doc) => {
			if (!handleDraftFiltering(doc.date, doc.ticker, filters)) return false
			return doc.peerReview !== "pending"
		}).sort((a, b) => {
			if (a.date > b.date) return -1
			else if (a.date < b.date) return 1
			else return 0
		})
		setData(sortedDrafts || [])
		setDraftsLoading(false)
	}

	const getImage = (t) => {
		const sector = gicsSectors.find(s => s.name === t)
		const theTicker = tickers && tickers.find(ticker => ticker.ric === t)

		if (theTicker) return theTicker.logo
		else if (sector) return sector.image
		else return ""
	}

	const headerWidths = ["19%", "12%", "15%", "auto", "11%", "12%", "150px"];
	const headers = ["Last Edited", "Coverage", "Template", "Last Edited By", "Compliance", "Peer Approval", "Actions"];

	const dataMap = data.length > 0 && data.map((document, idx) => {
		if (document) {
			return <DraftsTableRow
				data={document}
				idx={idx}
				tickers={tickers}
				expandedRows={expandedRows}
				setExpandedRows={setExpandedRows}
				getImage={getImage}
				refreshList={refreshList}
			/>
		}
	});

	return (
		<Table id="drafts-library-table" headerWidths={headerWidths} minWidth={1250} headers={headers} dataMap={dataMap} wrapperStyle={{ paddingTop: '2rem' }}/>
	);
}

export default DraftsLibraryTable;

export const RawDraftsLibraryTable = DraftsLibraryTable;
